<template>
<div id="home_slider" class="carousel slide" data-bs-ride="carousel">
  <div class="carousel-indicators">
    <button type="button" data-bs-target="#home_slider" data-bs-slide-to="0" class="active" aria-current="true" aria-label="Slide 1"></button>
    <button type="button" data-bs-target="#home_slider" data-bs-slide-to="1" aria-label="Slide 2"></button>
    <div class="goDown">
        <a href="#services">
            <i class="fas fa-chevron-down"></i>
        </a>       
    </div>
    <button type="button" data-bs-target="#home_slider" data-bs-slide-to="2" aria-label="Slide 3"></button>
    <button type="button" data-bs-target="#home_slider" data-bs-slide-to="3" aria-label="Slide 4"></button>
  </div>
  <div class="carousel-inner">
    <div class="carousel-item active">
      <img src="../../../public/assets/img/banners/visa_bg.webp" class="d-block w-100" alt="Rahalatna">
      <div class="carousel-caption d-none d-md-block" data-aos="zoom-in">
        <LocalizedLink to="/VisaFilling" class="nav-link scrollto">
          <p>{{$t("sections.sliders.slider2.title1")}}</p>
          <h5>{{$t("sections.sliders.slider2.title2")}}</h5>
        </LocalizedLink>
      </div>
    </div>
    <!-- <div class="carousel-item">
      <img src="../../../public/assets/img/slides/slide1.webp" class="d-block w-100" alt="Rahalatna">
      <div class="carousel-caption d-none d-md-block" data-aos="zoom-in">
        <p>{{$t("sections.sliders.slider1.title1")}}</p>
        <h5>{{$t("sections.sliders.slider1.title2")}}</h5>  
      </div>
    </div>
    <div class="carousel-item">
      <img src="../../../public/assets/img/slides/slide3.webp" class="d-block w-100" alt="Rahalatna">
      <div class="carousel-caption d-none d-md-block" data-aos="zoom-in">
        <p>{{$t("sections.sliders.slider3.title1")}}</p>
        <h5>{{$t("sections.sliders.slider3.title2")}}</h5> 
      </div>
    </div>

    <div class="carousel-item">
      <img src="../../../public/assets/img/slides/slide4.webp" class="d-block w-100" alt="Rahalatna">
      <div class="carousel-caption d-none d-md-block" data-aos="zoom-in">
        <p>{{$t("sections.sliders.slider4.title1")}}</p>
        <h5>{{$t("sections.sliders.slider4.title2")}}</h5>
      </div>
    </div>

    <div class="carousel-item">
      <img src="../../../public/assets/img/slides/slide5.webp" class="d-block w-100" alt="Rahalatna">
      <div class="carousel-caption d-none d-md-block" data-aos="zoom-in">
        <p>{{$t("sections.sliders.slider5.title1")}}</p>
        <h5>{{$t("sections.sliders.slider5.title2")}}</h5>
      </div>
    </div> -->

  </div>
  <!--Booking form -->
  <Booking />

  <button class="carousel-control-prev" type="button" data-bs-target="#home_slider" data-bs-slide="prev">
    <span class="carousel-control-prev-icon" aria-hidden="true"></span>
    <span class="visually-hidden">Previous</span>
  </button>
  <button class="carousel-control-next" type="button" data-bs-target="#home_slider" data-bs-slide="next">
    <span class="carousel-control-next-icon" aria-hidden="true"></span>
    <span class="visually-hidden">Next</span>
  </button>
</div>  
 
</template>

<script>
import Booking from '@/components/partials/Booking'
import LocalizedLink from '@/components/partials/LocalizedLink';
export default {
    name:"HomeSlider",
    components:{
      Booking,
      LocalizedLink
    }
}
</script>

<style lang="scss" scoped>
#home_slider .carousel-caption{
    height: 100%;
    width: 100%;
    display: flex !important;
    background: #131654cc;
    flex-direction: column;
    justify-content: flex-start;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    padding-top: 12%;
}

#home_slider p{
    color:#fff;
    font-size: 30px;
}

#home_slider h5{
    color:#fff;
    font-size: 30px;
    text-transform: uppercase;
    font-weight: bold;
    
}
html[lang="en"] #home_slider h5{
letter-spacing: 2px;
}
.carousel-indicators{
    display: flex;
    align-items: center;
}
@media screen and (min-width: 1150px){
    .carousel-indicators{
        top: 115% !important;
    }   
}
.carousel-control-next-icon, .carousel-control-prev-icon{
  opacity:0;
  background-image: none;
}

#home_slider:hover .carousel-control-next-icon,
#home_slider:hover  .carousel-control-prev-icon{
  opacity: 1;
  transition: 1s all ease-in-out;
}

 .carousel-control-prev span,
 .carousel-control-next span {  
   font-size: 16px;
    background-color: #fff;
        padding: 5px;
    height: 45px;
    width: 45px;
    line-height: 40px;
    border-radius: 50%;
    color: #131654;
    transition: 0.4s;
    z-index: 3000;
}

 .carousel-control-prev span:hover,
 .carousel-control-next span:hover{
   background: #fcca01;
 }

.carousel-control-next-icon:before{
  content:"\f054";
  font-family: "Font Awesome 5 Pro";
  -webkit-font-smoothing: antialiased;
    display: inline-block;
    font-style: normal;
    font-variant: normal;
    text-rendering: auto;
    line-height: 1;
}

.carousel-control-prev-icon:before{
  content:"\f053";
  font-family: "Font Awesome 5 Pro";
    -webkit-font-smoothing: antialiased;
    display: inline-block;
    font-style: normal;
    font-variant: normal;
    text-rendering: auto;
    line-height: 1;
}

.goDown{
    background: #fff;
    color: #131654;
    margin: 0 7px;
    padding: 5px;
    height: 40px;
    width:40px;
    line-height: 35px;
    border-radius: 50%;
    transition: 0.5s;
}

.goDown a{
    color:#295d93;
}

.goDown:hover{
    background: #fcca01;
    cursor: pointer;
}

.carousel-indicators button{
    height: 15px !important;
    width: 15px!important;
    border-radius: 50%;
    opacity: 1 !important;
    border:none !important;
}

.carousel-indicators .active{
    background: #fcca01;
     height: 15px !important;
    width: 15px!important;
}

@media screen and (max-width:767px) {
  #home_slider h5{
    font-size: 28px;
  }
  #home_slider p{
    font-size: 25px;
    margin: 15px 0 0;
  }
}
</style>