<template>
    <div>

    <section class="hotels_section p-0">
        <div class="banner_layer">
          <h1 class="text-capitalize text-white">{{$t("dashboard.1")}}</h1>
        </div>     
      </section>
        <section class="pt-150 pb-150">
            <div class="container">
                <div class="row">
                    <div class="col-lg-10 m-auto">
                        <div class="row">
                            <div class="col-md-4">
                                <div class="dashboard-menu">
                                    <ul class="nav flex-column" role="tablist">
                                        <li class="nav-item">
                                            <a class="nav-link active" id="dashboard-tab" data-bs-toggle="tab"
                                                href="#dashboard" role="tab" aria-controls="dashboard"
                                                aria-selected="false"><i class="fas fa-sliders-h mx-2"></i>{{$t("dashboard.1")}}</a>
                                        </li>
                                        <li class="nav-item">
                                            <a class="nav-link" id="orders-tab" data-bs-toggle="tab" href="#orders"
                                                role="tab" aria-controls="orders" aria-selected="false"><i class="fas fa-cart-arrow-down mx-2"></i>{{$t("dashboard.2")}}</a>
                                        </li>
                                        <!-- <li class="nav-item">
                                            <a class="nav-link" id="track-orders-tab" data-bs-toggle="tab"
                                                href="#track-orders" role="tab" aria-controls="track-orders"
                                                aria-selected="false"><i
                                                    class="fi-rs-shopping-cart-check mx-2"></i>{{$t("dashboard.3")}}</a>
                                        </li> -->
                                        <!-- <li class="nav-item">
                                            <a class="nav-link" id="address-tab" data-bs-toggle="tab" href="#address"
                                                role="tab" aria-controls="address" aria-selected="true"><i
                                                    class="fi-rs-marker mx-2"></i>{{$t("dashboard.4")}}</a>
                                        </li>
                                        <li class="nav-item">
                                            <a class="nav-link" id="account-detail-tab" data-bs-toggle="tab"
                                                href="#account-detail" role="tab" aria-controls="account-detail"
                                                aria-selected="true"><i class="fi-rs-user mx-2"></i>{{$t("dashboard.5")}}</a>
                                        </li> -->
                                        <li class="nav-item">
                                            <a class="nav-link" @click.prevent="logOut">
                                                <i class="fas fa-sign-out mx-2"></i>{{$t("dashboard.6")}}</a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div class="col-md-8">
                                <div class="tab-content dashboard-content">
                                    <div class="tab-pane fade active show" id="dashboard" role="tabpanel"
                                        aria-labelledby="dashboard-tab">
                                        <div class="card">
                                            <div class="card-header">
                                                <h5 class="mb-0">{{$t("dashboard.7")}} {{currentUser.name||currentUser.userData.name}} ! </h5>
                                            </div>
                                            <div class="card-body">
                                                <p>{{$t("dashboard.p1")}} <a
                                                        href="#">{{$t("dashboard.a1")}}</a>, {{$t("dashboard.p2")}} <a href="#">{{$t("dashboard.a2")}}</a> {{$t("dashboard.p3")}} <a href="#">{{$t("dashboard.a3")}}</a></p>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="tab-pane fade" id="orders" role="tabpanel" aria-labelledby="orders-tab">
                                        <div class="card">
                                            <div class="card-header">
                                                <h5 class="mb-0">{{$t("dashboard.orders")}}</h5>
                                            </div>
                                            <div class="card-body">
                                                <div class="table-responsive">
                                                    <table class="table">
                                                        <thead>
                                                            <tr>
                                                                <th>{{$t("dashboard.table.1")}}</th>
                                                                <th>{{$t("dashboard.table.2")}}</th>
                                                                <th>{{$t("dashboard.table.3")}}</th>
                                                                <th>{{$t("dashboard.table.4")}}</th>
                                                                <!-- <th>{{$t("dashboard.table.5")}}</th> -->
                                                            </tr>
                                                        </thead>
                                                        <tbody v-for="data in listOrders" :key="data.id">
                                                            <tr>
                                                                <td>{{data.id.substring(0,20)+".."}}</td>
                                                                <td>{{$d(new Date(data.created_at), "minshort")}}</td>
                                                                <td>{{data.status}}</td>
                                                                <td>SAR {{data.amount}}</td>
                                                                <!-- <td><a href="#" class="btn-small d-block">{{$t("dashboard.table.view")}}</a></td> -->
                                                            </tr>
                                                            
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="tab-pane fade" id="track-orders" role="tabpanel"
                                        aria-labelledby="track-orders-tab">
                                        <div class="card">
                                            <div class="card-header">
                                                <h5 class="mb-0">{{$t("dashboard.ordertruck")}}</h5>
                                            </div>
                                            <div class="card-body contact-from-area">
                                                <p>{{$t("dashboard.truck")}}</p>
                                                <div class="row">
                                                    <div class="col-lg-8">
                                                        <form class="contact-form-style mt-30 mb-50" action="#"
                                                            method="post">
                                                            <div class="input-style mb-20">
                                                                <label>{{$t("dashboard.orderId")}}</label>
                                                                <input name="order-id"
                                                                    :placeholder="$t('dashboard.num')"
                                                                    type="text" class="square">
                                                            </div>
                                                            <div class="input-style mb-20">
                                                                <label>{{$t("dashboard.buillingemail")}}</label>
                                                                <input name="billing-email"
                                                                    :placeholder="$t('dashboard.emailPlaceholder')"
                                                                    type="email" class="square">
                                                            </div>
                                                            <button class="submit submit-auto-width"
                                                                type="submit">{{$t("dashboard.truckBtn")}}</button>
                                                        </form>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="tab-pane fade" id="address" role="tabpanel"
                                        aria-labelledby="address-tab">
                                        <div class="row">
                                            <div class="col-lg-6">
                                                <div class="card mb-3 mb-lg-0">
                                                    <div class="card-header">
                                                        <h5 class="mb-0">{{$t("dashboard.badr")}}</h5>
                                                    </div>
                                                    <div class="card-body">
                                                        <address>3522 Interstate<br> 75 Business Spur,<br> Sault Ste.
                                                            <br>Marie, MI 49783</address>
                                                        <p>New York</p>
                                                        <a href="#" class="btn-small">{{$t("btns.edit")}}</a>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-lg-6">
                                                <div class="card">
                                                    <div class="card-header">
                                                        <h5 class="mb-0">{{$t("dashboard.sadr")}}</h5>
                                                    </div>
                                                    <div class="card-body">
                                                        <address>4299 Express Lane<br>
                                                            Sarasota, <br>FL 34249 USA <br>Phone: 1.941.227.4444
                                                        </address>
                                                        <p>Sarasota</p>
                                                        <a href="#" class="btn-small">{{$t("btns.edit")}}</a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="tab-pane fade" id="account-detail" role="tabpanel"
                                        aria-labelledby="account-detail-tab">
                                        <Profile />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>

<script>
    import EventBus from "../../common/EventBus";
    import Profile from './Profile.vue';
    import { mapMutations } from 'vuex';
    import http from "../../services/api";
    export default {
        name: "Account",
        components: {
            Profile,
        },
        data() {
            return {
                listOrders: [],
            }
        },
        created() {
            // this.getprofile();
            this.getuserorders(localStorage.getItem('user_id'));
        },
        mounted() {
            const path = "/login"
            if (!this.currentUser) {
                if (this.$route.path !== path) this.$router.push('Login');
            }else{
                this.setMyName(this.currentUser);
            }
        },

        beforeDestroy() {
            EventBus.remove("logout");
        },
        computed: {
            currentUser() {
                return this.$store.state.auth.user;
            }
        },
        methods: {
            getuserorders(userid){
                http
                    .get("/getuserorders/"+userid)
                    .then((res) => {
                        this.listOrders= res.data.data 
                })
            },
            logOut() {
                this.reset_setMyName(this.currentUser);
                this.$store.dispatch('auth/logout');
                this.$router.push('/');
            },
            ...mapMutations([
                'setMyName',
                'reset_setMyName'
            ]),
        }
    };
</script>

<style scoped>
.banner_layer{
    background: #131654cc;
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;
    padding: 200px 0 120px;
}
.hotels_section{
  background: url(../../../public/assets/img/banners/flights.webp) center no-repeat;
}
.dashboard-menu ul li {
    border: 1px solid #e2e9e1;
}
.dashboard-menu ul li a {
    font-size: 14px;
    padding: 15px 30px;
    text-align: initial;
}
.dashboard-menu ul li a.active {
    color: #fff !important;
    background-color: #131654;
}
</style>