<template>
<section id="our_partners">
    <div class="container">
        <div class="row">
            <div class="col">
                <h2 class="section_header">{{$t("sections.partners.title_part1")}} <strong>{{$t("sections.partners.title_part2")}}</strong></h2>
                <div class="after_title">
                    <div class="line"></div>
                    <div class="cercle yellow"></div>
                    <div class="cercle blue"></div>
                    <div class="cercle yellow"></div>
                    <div class="cercle blue"></div>
                    <div class="line"></div>
                </div>
                <swiper class="swiper" :options="swiperOption" data-swiper-autoplay="3000" :dir="isRtl">
                    <swiper-slide>
                        <img src="../../../public/assets/img/partners/partner1.webp" alt="partners" class="img-fluid" />
                    </swiper-slide>
                    <swiper-slide>
                        <img src="../../../public/assets/img/partners/partner2.webp" alt="partners" class="img-fluid" />
                        </swiper-slide>
                    <swiper-slide>
                        <img src="../../../public/assets/img/partners/partner3.webp" alt="partners" class="img-fluid" />
                    </swiper-slide>
                    <swiper-slide>
                        <img src="../../../public/assets/img/partners/partner4.webp" class="img-fluid" alt="partners" />
                    </swiper-slide>
                    <swiper-slide>
                        <img src="../../../public/assets/img/partners/partner5.webp" alt="partners" class="img-fluid" />
                    </swiper-slide>
                    <swiper-slide>
                        <img src="../../../public/assets/img/partners/partner6.webp" alt="partners" class="img-fluid" />
                    </swiper-slide>
                    <swiper-slide>
                        <img src="../../../public/assets/img/partners/partner7.webp" alt="partners" class="img-fluid" />
                    </swiper-slide>
                    
                     <!-- If we need navigation buttons -->
                    <div class="swiper-button-prev" slot="button-prev"></div>
                    <div class="swiper-button-next" slot="button-next"></div>
                </swiper> 
            </div>
        </div>
    </div>
 
</section>

</template>

<script>
import { Swiper, SwiperSlide, directive } from 'vue-awesome-swiper';
import 'swiper/css/swiper.css';

export default {
    name:"Partners",
    components: {
      Swiper,
      SwiperSlide,
    },
    directives: {
        swiper: directive
    },
    data() {
      return {
        isRtl: this.getLocale(),
        swiperOption: {
            slidesPerView: 7,
            spaceBetween: 50,
            direction: 'horizontal',
            loop: true,
            allowTouchMove: true,
            speed: 1000,          
            autoplay: {
            delay: 3000,
            centeredSlides: true,
            },
            navigation: {
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev'
          },
          breakpoints: {
            200:{
            slidesPerView:5,
            spaceBetween: 25,
            },
            1024: {
            slidesPerView: 7,
            spaceBetween: 50,
            },
        }
        }
      }
    },
    computed: {
      swiper() {
        return this.$refs.mySwiper.$swiper
      }
    },
    mounted() {
        
    },
    methods:{
        getLocale() {
            const locale = this.$route.params.locale =="ar" ? "rtl" :"ltr";
            return locale;
        }
    }
}
</script>

<style scoped>

#our_partners .swiper-button-next,#our_partners .swiper-container-rtl .swiper-button-prev,
#our_partners .swiper-button-prev,#our_partners .swiper-container-rtl .swiper-button-next{
    opacity: 0;
}

#our_partners:hover .swiper-button-next,#our_partners:hover .swiper-container-rtl .swiper-button-prev,
#our_partners:hover .swiper-button-prev,#our_partners:hover .swiper-container-rtl .swiper-button-next{
    opacity: 1;
    transition: 0.4s;
}


.swiper-button-next:after, .swiper-container-rtl .swiper-button-prev:after,
.swiper-button-prev:after, .swiper-container-rtl .swiper-button-next:after{
    font-size: 16px;
    background: #ebebeb;
    padding: 10px 15px;
    border-radius: 50%;
    color: #131654;
    transition: 0.4s;
    border: 1px solid #ebebeb;
}
.swiper-button-next:hover:after, .swiper-container-rtl .swiper-button-prev:hover:after,
.swiper-button-prev:hover:after, .swiper-container-rtl .swiper-button-next:hover:after{
    color: #ebebeb;
    background: #131654;
    border-color: #ebebeb;
}
.swiper-pagination-fraction, .swiper-pagination-custom, .swiper-container-horizontal > .swiper-pagination-bullets{
    top:0;
}

#our_partners .swiper-wrapper,
#our_partners .swiper-slide{
    display: flex;
    align-items: baseline;
    height: 140px;
}
.swiper-slide{
      display: -webkit-flex;
      display: flex;
      -webkit-justify-content: center;
      justify-content: center;
      align-items: center;
}

#our_partners .swiper-slide img{
    margin: auto;
}

.swiper-pagination-bullet{
    background: #131654;
    opacity: 1;
}
.swiper-pagination-bullet-active{
  background: #fcca01!important;
  opacity: 1;
}
</style>