class TokenService {
  getLocalRefreshToken() {
    // const user = JSON.parse(localStorage.getItem("user"));
    // return user?.refreshToken;
    return localStorage.getItem("refreshjwt");
  }

  getLocalAccessToken() {
    // const user = JSON.parse(localStorage.getItem("user"));
    // return user?.accessToken;
    return localStorage.getItem("jwt");
  }

  updateLocalAccessToken(token) {
    // let user = JSON.parse(localStorage.getItem("user"));
    // user.accessToken = token;
    // localStorage.setItem("user", JSON.stringify(user));
    // console.log(token, 'updateLocalAccessToken');
    localStorage.setItem("jwt", token);
  }

  getUser() {
    return JSON.parse(localStorage.getItem("user"));
  }

  setUser(user) {
    // console.log(JSON.stringify(user));
    localStorage.setItem("user", JSON.stringify(user.userData));
    localStorage.setItem("user_id", JSON.stringify(user.userData.id));
    localStorage.setItem('jwt', user.accessToken)
    localStorage.setItem('refreshjwt', user.refreshToken)
  }

  removeUser() {
    localStorage.removeItem("user");
    localStorage.removeItem("user_id");
  }
}

export default new TokenService();
