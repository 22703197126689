<template>
   
     <main id="main">
      <section class="hotels_section p-0">
           <CompactSearch /> 
      </section>

      <section class="ecommerce">
        <div class="container">
          <div class="row">
            <!-- <SearchFilters />
            <SearchResults /> -->
            <div class="ok">
              <h1 class="text-uppercase">{{$t('header.menu.hotels')}}</h1>
            </div>
            <h2 class="txt404">{{$t("notfount.car")}}</h2>
          </div>
        </div>
      </section>
      <Newsletter />
     </main><!-- End #main -->
 
</template>

<script>
  import Newsletter from '@/components/partials/Newsletter';
  import CompactSearch from '@/components/partials/CompactSearch'
  // import SearchFilters from '@/components/partials/SearchFilters'
  // import SearchResults from '@/components/partials/SearchResults'

export default {
  name: 'hotels',
  metaInfo:{
    title:'Hotels | Search Hotel'
  },
  components:{
    Newsletter,
    CompactSearch,
    // SearchFilters,
    // SearchResults
  }
}
</script>
<style scoped>
.ok{
  background-image: url(../../public/assets/404/hotels.webp);
  height: 259px;
  background-repeat: no-repeat;
  background-position: 50%;
  background-size: 21% auto;
}
</style>

