<template>
    <div>
        <form @submit.prevent="handleSubmit">
            <div class="form-group mb-3">
                <input type="text" v-model="form.email" autofocus
                    :class="{ 'is-invalid': isSubmitted && $v.form.email.$error }"
                    class="form-control"  
                    name="email"
                    :placeholder="$t('account.mail')">
                <div v-if="isSubmitted && $v.form.email.$error" class="invalid-feedback">
                    <span v-if="!$v.form.email.required">{{$t("account.reqEmail")}}</span>
                    <span v-if="!$v.form.email.email">{{$t("form.validation.emailvalid")}}</span>
                </div>
            </div>
            <div class="form-group mb-3">
                <input type="password" v-model="form.password"
                 class="form-control" 
                    :class="{ 'is-invalid': isSubmitted && $v.form.password.$error }" name="password"
                    :placeholder="$t('account.pwd')">
                <div v-if="isSubmitted && $v.form.password.$error" class="invalid-feedback">
                    <span v-if="!$v.form.password.required">{{$t("form.validation.pwdreq")}}</span>
                    <span v-if="!$v.form.password.minLength">{{$t("account.fivelong")}}</span>
                </div>
            </div>
            <div class="login_footer form-group mb-3">
                <div class="chek-form">
                    <div class="custome-checkbox">
                        <input class="form-check-input" type="checkbox" name="checkbox" id="exampleCheckbox1" value="">
                        <label class="form-check-label"
                            for="exampleCheckbox1">&nbsp;<span>{{$t("account.remember")}}</span>&nbsp;</label>
                    </div>
                </div>
                <a class="text-muted" href="#">{{$t("account.forget")}}</a>
            </div>
            <div class="form-group mt-5">
                <button type="submit" class="btn btn-primary btn-block w-100">{{ $t("btns.login")}}</button>
            </div>
        </form>
    </div>
</template>

<script>
    import {
        required,
        email,
        minLength
    } from "vuelidate/lib/validators";
    export default {
        name: "LoginForm",
        mounted() {

        },
        data() {
            return {
                form: {
                    email: "",
                    password: ""
                },
                isSubmitted: false,
                message: '',
                ListRouteComeBack:[
                    'IDTranslation',
                    'VisaFilling',
                    'DrivingLicence'
                ]
            }
        },
        validations: {
            form: {
                email: {
                    required,
                    email
                },
                password: {
                    required,
                    minLength: minLength(5)
                },
            },
        },
        created() {
  
        },
        computed: {

        },
        methods: {
            handleSubmit() {
                this.isSubmitted = true;
                this.$v.form.$touch();
                if (this.$v.form.$invalid) {
                    return;
                }
                this.Submit(this.form)
            },
            Submit(form) {
                this.$store.dispatch('auth/login', form).then(
                    () => {
                        this.ListRouteComeBack.includes(localStorage.getItem('LS_ROUTE_KEY')) ? this.$router.go(-1) :this.$router.push('dashboard');
                    },
                    error => {
                        this.message =
                            (error.response && error.response.data && error.response.data.message) ||
                            error.message ||
                            error.toString();
                    }
                );

            }
        }
    };
</script>

<style lang="scss" scoped></style>