<template>
    
    <section id="services" class="services">
      <div class="container" data-aos="fade-up">

        <div class="row pt-4">

          <div class="col">
            <div class="box" data-aos="zoom-in" data-aos-delay="100">
              <span>01.</span>
              <div class="img-wrapper">
                    <LocalizedLink to="/VisaFilling" class="nav-link scrollto" >
                    <img src="../../../public/assets/img/services/service1.webp" alt="Visa" />
                    </LocalizedLink>
              </div>     
               <h4>
                 <LocalizedLink to="/VisaFilling" class="nav-link scrollto" >{{$t("sections.services.service1")}}</LocalizedLink>
               </h4>
            </div>
          </div>

           <div class="col">
            <div class="box" data-aos="zoom-in" data-aos-delay="100">
              <span>02.</span>
              <div class="img-wrapper">
                   <LocalizedLink to="/hotel" class="nav-link scrollto">
                   <img src="../../../public/assets/img/services/service2.webp" alt="Hotel Reservation" />
                   </LocalizedLink>
              </div> 
              
               <h4><LocalizedLink to="/hotel" class="nav-link scrollto">{{$t("sections.services.service2")}}</LocalizedLink></h4>
            </div>
          </div>

           <div class="col">
            <div class="box" data-aos="zoom-in" data-aos-delay="100">
              <span>03.</span>
              <div class="img-wrapper">
                  <LocalizedLink to="/flights" class="nav-link scrollto"><img src="../../../public/assets/img/services/service3.png" alt="Flight reservation" /></LocalizedLink>
              </div> 
              
               <h4><LocalizedLink to="/flights" class="nav-link scrollto">{{$t("sections.services.service3")}}</LocalizedLink></h4>
            </div>
          </div>

           <div class="col">
            <div class="box" data-aos="zoom-in" data-aos-delay="100">
              <span>04.</span>
              <div class="img-wrapper">
                <LocalizedLink to="/rent-car" class="nav-link scrollto">
                  <img src="../../../public/assets/img/services/service4.webp" alt="Rent a Car" />
                </LocalizedLink>
              </div> 
              
               <h4><LocalizedLink to="/rent-car" class="nav-link scrollto">{{$t("sections.services.service4")}}</LocalizedLink></h4>
            </div>
          </div>

           <div class="col">
            <div class="box" data-aos="zoom-in" data-aos-delay="100">
              <span>05.</span>
              <div class="img-wrapper">
                    <LocalizedLink to="/driving-licence" class="nav-link scrollto">
                    <img src="../../../public/assets/img/services/service5.webp" alt="Driving License" />
                    </LocalizedLink>
              </div> 
              
               <h4> <LocalizedLink to="/driving-licence" class="nav-link scrollto">{{$t("sections.services.service5")}}</LocalizedLink></h4>
            </div>
          </div>

        </div>

      </div>
    </section>
</template>

<script>
import LocalizedLink from '@/components/partials/LocalizedLink'
export default {
    name:"Services",
    components:{
      LocalizedLink
    }
}
</script>

<style scoped>
    .services .box .img-wrapper a{
      width:100px;
      height:100px;
      margin: auto;
      display: contents;
    }
    .services{
      background: url(../../../public/assets/img/services/liner.webp) no-repeat center;
      background-size: contain;
    }
    .nav-link:focus, .nav-link:hover{
      color: #fcca01 !important;
      transition: all 0.4s;
    }
</style>