<template>
   
     <main id="main">
       <HomeSlider />
       
       <Services />
  
      <TopDestinations />

      <Newsletter />
      
      <WhyUs />
 

  </main><!-- End #main -->
 
</template>
<script>
  import WhyUs from '@/components/partials/WhyUs';
  import TopDestinations from '@/components/partials/TopDestinations';
  import Services from '@/components/partials/Services'; 
  import Newsletter from '@/components/partials/Newsletter';
  import HomeSlider from '@/components/partials/HomeSlider'

export default {
  name: 'Home',
  metaInfo:{
    title:'Rahalatna | Home Page'
  },
  components:{
    HomeSlider,
    TopDestinations, 
    Services,
    Newsletter,
    WhyUs
  }
}
</script>
