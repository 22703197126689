<template>
    <main id="main">
      <section class="hotels_section p-0">
        <div class="banner_layer">
          <h1 class="text-capitalize text-white">{{$t('labels.signIn')}}</h1>
        </div>     
      </section>
      <section>
            <div class="container">
                <div class="row">
  
                            <div class="col-md-6">
                                <div
                                    class="blue_border login_wrap widget-taber-content p-30 background-white border-radius-10 mb-md-5 mb-lg-0 mb-sm-5">
                                    <div class="padding_eight_all bg-white">
                                        <div class="heading_s1">
                                            <h3 class="mb-30">{{ $t("btns.login")}}</h3>
                                        </div>
                                        <login-form/>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-6">
                                <Register class="blue_border" />
                            </div>
                </div>
            </div>   
      </section>
      <Newsletter />
     </main>

</template>

<script>
    import Register from "./Register.vue";
    import LoginForm from './LoginForm.vue';
    import Newsletter from '@/components/partials/Newsletter';
    
    export default {
        name: "Login",
        components: {
            Register,
            LoginForm,
            Newsletter,
        },
        mounted() {

        },
        data() {
            return {
            }
        },

        created() {

        },
        computed: {

        },
        methods: {
            handleSubmit() {
                this.isSubmitted = true;
                this.$v.form.$touch();
                if (this.$v.form.$invalid) {
                    return;
                }
                this.Submit(this.form)
            },
        }
    };
</script>

<style  scoped>
.banner_layer{
    background: #131654cc;
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;
    padding: 200px 0 120px;
}
.hotels_section{
  background: url(/../../../public/assets/img/banners) center no-repeat;

}

.mb-30{
    margin-bottom: 30px;
}
.blue_border{
    border: 1px solid #e2e9e1;
    border-radius: 10px;
    padding: 35px 20px;
}
.invalid-feedback{
    text-align: initial;
}

@media screen and (max-width:767px){
    .blue_border{
        margin-bottom: 20px;
    }
}
</style>