<template>
     
     <main id="main">
      <section class="hotels_section with_overlay py-100">
        <div></div>
      </section>
      <section>
      <div class="container px-5 error">
        <div class="row gx-5 justify-content-center">
            <div class="col-lg-6">
                <div class="text-center mt-4">
                    <img class="img-fluid p-4"
                        src="https://sb-ui-kit-pro.startbootstrap.com/assets/img/illustrations/404-error.svg"
                        alt="404" />
                    <h1>{{ $t('404.content.title') }}</h1>
                    <p class="lead">{{ $t('404.content.desc') }}</p>
                    <LocalizedLink :to="''">
                        <a class="text-arrow-icon">
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                                stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                                class="feather feather-arrow-left ms-0 me-1">
                                <line x1="19" y1="12" x2="5" y2="12"></line>
                                <polyline points="12 19 5 12 12 5"></polyline>
                            </svg>
                            <span class="mx-1">{{ $t('404.action') }}</span>
                        </a>
                    </LocalizedLink>
                </div>
            </div>
        </div>
      </div>
      </section>
      
      <Newsletter />
     </main>
    
</template>

<script>
  import Newsletter from '@/components/partials/Newsletter';
  import LocalizedLink from '@/components/partials/LocalizedLink'

export default {
  name: 'NotFound',
  metaInfo:{
    title:'404 | Not Found'
  },
  components:{
    Newsletter,
    LocalizedLink
  }
}
</script>