<template>
 
    <section id="newsletter_subscription" class="newsletter_subscription">
      <div class="container-fluid" data-aos="fade-up">

        <div class="section-header_light">
          <h2 class="text-capitalize">{{$t("sections.newsletter")}}</h2>
          <div class="after_title_white">
                    <div class="line"></div>
                    <div class="cercle white"></div>
                    <div class="cercle white"></div>
                    <div class="cercle white"></div>
                    <div class="cercle white"></div>
                    <div class="line"></div>
                </div>
        </div>

        <div class="row newsletter_form" data-aos="fade-up" data-aos-delay="100">
          <div class="col-12 text-center">
            <form action="" method="post" class="d-flex align-items-center flex-row-reverse">
              <input type="email" class="email" name="email" :placeholder="$t('placeholders.email')">
              <input type="submit" :value="$t('buttons.send')" class="send">
            </form>
          </div>
        
        </div>

      </div>
    </section><!-- End newsletter_subscription Section -->

</template>
<script>
export default {
   name:"Newsletter"
}
</script>

<style scoped>

.after_title_white{
  display:flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  margin: 20px 0;
}
.after_title_white .cercle.white{
    height:15px;
    width:15px;
    border-radius: 50%;
    margin: 0 3px;
    background: #fff;
}

.after_title_white .line{
  border-top: 1px solid #fff;
  width: 50px;
  margin: 0 5px;
}

.section-header_light{
    color: #fff;
    font-weight: 700;
    padding:0;
}

.newsletter_form form input{
    border-radius: 30px;
    border: none;
    box-shadow: none;
    outline: none;
    height: 45px;
}

.newsletter_form form img{
    position: absolute;
    margin-right: -50px;
}

.newsletter_form form input.send{
    background: #fcca01;
    color: #fff;
    position: absolute;
    padding: 0 30px;
    border: 1px solid #fcca01;
}

.newsletter_form form input.send:hover,
.newsletter_form form input.send:focus{
    background: #131654;
    border-color: #fff;
    transition: 1s ease-in-out;
}

.newsletter_form .col-12{
    display: flex;
    justify-content: center;
}
@media screen and (max-width:991){
  .newsletter_form form{
    width:100%;
  }
}

@media screen and (min-width:992){
  .newsletter_form form{
    width:40%;
  }
}
@media screen and (max-width:423px) {
  html[lang="ar"] .newsletter_form form input.email{
    max-width: 100%;
    min-width: 290px;
  }
  html[lang="en"] .newsletter_form form input.email{
    max-width: 100%;
    min-width: 290px;
  }
}
</style>