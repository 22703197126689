<template>
  <main id="main">
    <section class="hotels_section p-0">
      <CompactSearch />
    </section>

    <section class="ecommerce">
      <div class="container">
        <div class="row">
          <div class="ok">
            <h1 class="text-uppercase">{{$t('header.menu.rent_car')}}</h1>
          </div>
          <h2 class="txt404">{{$t("notfount.car")}}</h2>
        </div>
      </div>
    </section>
    <Newsletter />
  </main>
</template>

<script>
  import Newsletter from "@/components/partials/Newsletter";
  import CompactSearch from "@/components/partials/CompactSearch";
  export default {
    name: "RentCar",
    components: {
      Newsletter,
      CompactSearch
    },
    methods: {
      formSubmitted() {

      }
    }
  }
</script>

<style lang="scss" scoped>
  .ok {
    background-image: url(../../public/assets/404/car.webp);
    height: 259px;
    background-repeat: no-repeat;
    background-position: 50%;
    background-size: 21% auto;
  }
  .hotels_section {
    background: url(../../public/assets/img/banners/car_bg.webp) center no-repeat;
  }
</style>