<template>
    <section id="about_us">
    <div class="container">
        <div class="row">
            <div class="col">
                <h2 class="section_header">{{$t("sections.why.title_part1")}} <strong>{{$t("sections.why.title_part2")}}</strong></h2>
                <div class="after_title">
                    <div class="line"></div>
                    <div class="cercle yellow"></div>
                    <div class="cercle blue"></div>
                    <div class="cercle yellow"></div>
                    <div class="cercle blue"></div>
                    <div class="line"></div>
                </div>
              
            </div>
        </div>
        <div class="row align-items-center py-5">
            <div class="col-lg-3 col-sm-6 left_side">
                <div class="play">
                   <i class="fas fa-caret-right"></i>
                </div>
                <h3>{{$t("sections.why.subTitle3")}}</h3>
                <p>{{$t("sections.why.subTitle4")}}</p>
            </div>
            <div class="col-lg-3 col-sm-6">
                <img src="../../../public/assets/img/gallery/friends.webp" alt="partners" class="img-fluid" />
            </div>

            <div class="col-lg-6 col-sm-12 mt-sm-4 right_side">
                <h3>{{$t("sections.why.subTitle1")}}
                    <span class="grey_title">{{$t("sections.why.subTitle2")}}</span>
                </h3>
                <div class="seprator"></div>
                <p>
                    {{$t("sections.why.desc")}}
                </p>
            </div>
            
        </div>

        <div class="row bordered_top" data-aos="fade-left">
            <div class="col-md-4">
               <div class="card">
                    <img src="../../../public/assets/img/gallery/serv1.webp" class="card-img-top" alt="Advantage1">
                    <div class="card-body">
                        <h4>{{$t("sections.why.advantages.adv1.title")}}</h4>
                        <p>
                            {{$t("sections.why.advantages.adv1.desc")}}
                        </p>
                    </div>
                </div>
            </div>
            <div class="col-md-4">
                <div class="card">
                    <img src="../../../public/assets/img/gallery/serv2.webp" class="card-img-top" alt="Advantage2">
                    <div class="card-body">
                        <h4>{{$t("sections.why.advantages.adv2.title")}}</h4>
                        <p>
                           {{$t("sections.why.advantages.adv2.desc")}}
                        </p>
                    </div>
                </div>
            </div>
            <div class="col-md-4">
                <div class="card">
                    <img src="../../../public/assets/img/gallery/serv3.webp" class="card-img-top" alt="Advantage3">
                    <div class="card-body">
                        <h4>{{$t("sections.why.advantages.adv3.title")}}</h4>
                        <p>
                            {{$t("sections.why.advantages.adv3.desc")}}
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </div>
 
</section>
</template>

<script>
export default {
    name:"WhyUs"
}
</script>

<style scoped>
.grey_title{
   display: block;
   color:#454545;
}

.play i{
    font-size: 3rem;
    margin: auto;
     width: 80px;
    height:80px;
    line-height: 80px;
    background: #efefef;
    color: #131654;
    border-radius: 50%;
    margin: 20px auto;
    text-align: center;
    padding: 0 10px 0 20px;
}

.right_side h3{
    color: #121750;
    font-size: 30px;
    font-weight: bold;
}

.right_side p{
font-weight: 400;
padding: 0 20px;
}

.seprator{
    width: 100px;
    border-top: 1px solid #949598;
    margin: 30px auto;
}

.bordered_top{
    border-top: 2px solid #f1f1f1;
    padding: 50px 0 0;
}

.bordered_top .card{
    border: none;
    padding: 20px;
}

.bordered_top .card img{
    margin-bottom: 20px;
    height: 220px;
    width: auto;
    margin: auto;
}

@media screen and (max-width:991px) {
    .bordered_top .card{
        padding: 0;
    }
    .bordered_top .card img{
        height:auto;
        max-width: 100%;
    }
}

.bordered_top .card h4{
    color: #131654;
    padding: 15px 0;
    font-size: 20px;
}

.left_side h3{
    color:#131654;
    font-weight: bold;
}

.left_side p{
    font-weight: 600;
    font-size: 22px;
}
</style>