import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home'
import Hotels from '@/views/Hotels'
import Flights from '@/views/Flights'
import NotFound from '@/views/NotFound'
import Login from "@/components/account/Login.vue"
import ResetPassword from '@/views/ResetPassword'
import VisaFilling from '@/views/VisaFilling'
import DrivingLicence from '@/views/DrivingLicence'
import IDTranslation from '@/views/IDTranslation'
import RentCar from '@/views/RentCar'
import Account from "@/components/account/Account.vue"

import Root from "./Root"
import i18n, { loadLocaleMessagesAsync } from "@/i18n"
import {
  setDocumentDirectionPerLocale,
  setDocumentLang,
  setDocumentTitle
} from "@/util/i18n/document"

Vue.use(VueRouter)

const { locale } = i18n

const routes = [
  {
    path: "/",
    redirect: locale
  },
  {
    path: '/:locale',
    component: Root,
    children: [
    
    {
      path: '',
      name: 'Home',
      component: Home
    },
    {
      path: 'login',
      name: 'Login',
      component: Login
    },
    {
      path: 'dashboard',
      name: 'Account',
      component: Account,
      meta: {
        requiresAuth: true
      }
    },
    {
      path: 'passwordReset',
      name: 'ResetPassword',
      component: ResetPassword
    },
    {
      path: 'hotel',
      name: 'Hotels',
      component: Hotels
    },
    {
      path: 'hotel/:id(\\d+)',
      component: () =>
        import(/* webpackChunkName: "about" */ "../views/SelectedHotel.vue"),
      name: 'selectedHotel',
      props: true,
      meta: { title: 'Hotel', noCache: true },
      hidden: true,
    },
    {
      path: 'flights',
      name: 'Flights',
      component: Flights
    },
    {
      path: 'flights/:id(\\d+)',
      component: () =>
        import(/* webpackChunkName: "about" */ "../views/SelectedFlight.vue"),
      name: 'SelectedFlight',
      props: true,
      meta: { title: 'Flight', noCache: true },
      hidden: true,
    },
    {
      path: 'VisaFilling',
      name: 'VisaFilling',
      component: VisaFilling,
      // meta: {
      //   requiresAuth: true
      // }
    },
    {
      path: 'driving-licence',
      name: 'DrivingLicence',
      component: DrivingLicence,
    },
    {
      path: 'translation',
      name: 'IDTranslation',
      component: IDTranslation,
    },
    {
      path: 'rent-car',
      name: 'RentCar',
      component: RentCar
    },
    {
      path: '*',
      name: '404',
      component: NotFound
    },
  ]
  }
]

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
  scrollBehavior() {
    document.getElementById('app').scrollIntoView({ behavior: 'smooth' });
  }
  // linkActiveClass: "active", // active class for non-exact links.
  // linkExactActiveClass: "active" // active class for *exact* links.
})


router.beforeEach((to, from, next) => {
  if (to.params.locale === from.params.locale) {
    localStorage.setItem('LS_ROUTE_KEY', from.name);
    if (to.name) {
      // Start the route progress bar.
      // NProgress.start()
    }
    next()
    if (to.matched.some(record => record.meta.requiresAuth)) {
      if (localStorage.getItem('jwt') == null) {
          next({
            path: "Login",
            params: { nextUrl: to.fullPath }
          })
      } else {
        next()
      }
    }
    return
  }

  const { locale } = to.params

  loadLocaleMessagesAsync(locale).then(() => {
    setDocumentLang(locale)

    setDocumentDirectionPerLocale(locale)

    setDocumentTitle(i18n.t("app.title"))
  })

  next()
})

export default router
