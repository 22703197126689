
<template>
    
        <div class="container reservation" id="reservation-form" data-aos="fade-down">
            <div class="row">
                <div class="col-md-12">
                    <div class="reserve">
                       <nav class="main_nav">
                            <div class="nav nav-tabs" id="nav-tab" role="tablist">
                                <a class="nav-link active" id="nav-home-tab" data-bs-toggle="tab" href="#nav-home" role="tab" aria-controls="nav-home" aria-selected="true">
                                    <img src="../../../public/assets/img/icons/plane.webp" alt="Flights" />
                                    {{$t("sections.booking.flight")}}
                                </a>
                                <a class="nav-link" id="nav-profile-tab" data-bs-toggle="tab" href="#nav-profile" role="tab" aria-controls="nav-profile" aria-selected="false">
                                    <img src="../../../public/assets/img/icons/hotel.webp" alt="Hotels" />
                                        {{$t("sections.booking.hotels")}}
                                </a>
                                <a class="nav-link" id="nav-contact-tab" data-bs-toggle="tab" href="#nav-contact" role="tab" aria-controls="nav-contact" aria-selected="false">
                                    <img src="../../../public/assets/img/icons/car.webp" alt="Car Rental" />
                                      {{$t("sections.booking.car")}}
                                </a>
                            </div>
                            </nav>
                            <div class="tab-content" id="nav-tabContent">
                            <div class="tab-pane fade show active" id="nav-home" role="tabpanel" aria-labelledby="nav-home-tab">
                                 <CompactBooking />
                            </div>
                            <div class="tab-pane fade" id="nav-profile" role="tabpanel" aria-labelledby="nav-profile-tab">
                               <CompactBooking />
                            </div>
                            <div class="tab-pane fade" id="nav-contact" role="tabpanel" aria-labelledby="nav-contact-tab">
                                <CompactBooking />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    
</template>

<script>
import CompactBooking from '@/components/partials/CompactBooking'
export default {
   name:"Booking",
   components:{
       CompactBooking
   }
}
</script>

<style scoped>
.reservation{
    position: relative;
    width: 100%;
    z-index: 6000;
}

.reserve .tab-content{
    background: #fff;
}

.reservation .tab-content{
    box-shadow: 0 2px 32px 0px rgba(53, 35, 69, .14);
    padding: 0;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    background: #fff;
}

.reservation .nav-tabs {
    border-bottom: 1px solid transparent;
    margin-bottom: 7px;
}
#reservation-form .search-opt .nav-tabs .nav-link:last-child{
     border-right: 1px solid transparent;
}
#reservation-form .search-opt .nav-tabs .nav-link:last-child {
    border-top-right-radius: 6px;
    padding: 0px 61.7px 0 55px;
}
html[lang="en"] #reservation-form .nav-tabs .nav-link{
   margin-right: 10px;
}

html[lang="ar"] #reservation-form .nav-tabs .nav-link{
   margin-left: 10px;
}
#reservation-form .nav-tabs .nav-link {
   
    background: #131654;
    display: block;
    text-align: center;
    color: #fff !important;
    font-size: 15px;
    font-weight: 500;
    height: 45px;
    line-height: 50px;
    text-decoration: none;
    text-transform: capitalize;
    border-radius: 5px;
    transition: 0.5s;
    display: flex;
    align-items: center;
    border: none;
}

@media screen and (min-width:992px){
    #reservation-form .nav-tabs .nav-link{
        padding: 0px 50px;
    }
    #reservation-form {
    margin-top: -31%;
}
}
@media screen and (max-width:991px){
    #reservation-form .nav-tabs .nav-link{
        padding: 0px 20px;
    }
    #reservation-form {
    margin-top: -20%;
}
}

@media screen and (min-width: 1050px){
    #reservation-form{
        margin-bottom:12.5%;
    } 
    .carousel-indicators{
        top: 110% !important;
    }   
}
@media screen and (min-width: 1800px){
    #reservation-form{
        margin-bottom:18%;
    } 
}
#reservation-form .nav-tabs .nav-link img{
    max-width: 20px;
    margin:0 10px;
}
 #reservation-form .nav-tabs .nav-link.active, #reservation-form .nav-tabs .nav-link:hover{
    background: #fcca01;
    color: #131654!important;
}
html[lang="en"] #reservation-form .nav-tabs .nav-link i{
    padding-right: 10px;
}

html[lang="ar"] #reservation-form .nav-tabs .nav-link i{
    padding-left: 10px;
}

html[lang="ar"] ul.nav{
  padding-right: 0;
} 
.reservation .tab-content{
    margin-top: 0;
    background: #fff;
}
.content-checkin-data label{

   font-size: 18px;
}
.content-checkin-data label i{
    padding-right: 5px;
    color: #131654;
}
.content-checkin-data input{
    width: 100%;
    position: relative;
    padding: 6px 0;
    font-size: 13px!important;
    color: #999!important;
    border: 1px solid #ddd;
    padding-left: 8px;
}
.content-checkin-data{
    width: 100%;
}
.banner-btn{
    width: 100%;
    background: #131654;
    font-size: 18px;
    text-transform: capitalize;
    color: #fff;
    padding: 4px 0;
    border: none;
}

.content-checkin-data input::-webkit-input-placeholder{
     color: #999!important;
    font-size: 13px!important;
}

@media all and (max-width:767px){
    #reservation-form{
        display:none;
    }
}
</style>