
<template>
    <section class="banner_layer">
        <div id="search-form" class="find-form">
            <div class="container">
                <form class="findfrom-wrapper">
                    <div class="row g-2">
                        <div class="col-sm-2">
                            <label>{{$t('labels.destination')}}</label>
                            <div class="input-group">
                                <div class="input-group-text">
                                   <img src="../../../public/assets/img/icons/location.webp" alt="Rahalatna" /> 
                                </div>
                                <input type="text" class="form-control" 
                                id="destination" 
                                :placeholder="$t('placeholders.destination')">
                            </div>
                        </div>
                        <div class="col-sm-2">
                            <label>{{$t('labels.checkin')}}</label>
                            <div class="input-group">
                                <div class="input-group-text">
                                   <img src="../../../public/assets/img/icons/calendar.webp" alt="Rahalatna" /> 
                                </div>
                                <input type="date" class="form-control" id="checkIn"
                                placeholder="10/07/2021">
                            </div>
                        </div>
                        <div class="col-sm-2">
                            <label>{{$t('labels.checkout')}}</label>
                            <div class="input-group">
                                <div class="input-group-text">
                                    <img src="../../../public/assets/img/icons/calendar.webp" alt="Rahalatna" /> 
                                </div>
                                <input type="date" class="form-control" id="checkOut" placeholder="10/07/2021">
                            </div>
                        </div>
                        <div class="col-sm-2">
                            <label>&nbsp;</label>
                            <div class="input-group">
                                <div class="input-group-text">
                                    <img src="../../../public/assets/img/icons/nights.webp" alt="Rahalatna" /> 
                                </div>
                                <input type="text" class="form-control" id="numberNights" :placeholder="$t('placeholders.nights')">
                            </div>
                        </div>
                        <div class="col-2">
                          <div class="form-group">
                            <div class="content-checkin-data">
                            <label for="noNights">{{$t('labels.noNights')}}</label>
                            <div class="input-group">
                                <div class="input-group-text">
                                   <img src="../../../public/assets/img/icons/user.webp" alt="Rahalatna" /> 
                                </div>
                                <select class="form-select inputstl" id="noNights" name="noNights">
                                    <option selected>{{$t('placeholders.guest')}}</option>
                                    <option value="1">One</option>
                                    <option value="2">Two</option>
                                    <option value="3">Three</option>
                                </select>
                            </div>
                            </div>
                          </div>
                        </div>
                        <div class="col">
                            <label>&nbsp;</label>
                            <button type="submit" class="btn btn-primary">{{$t('buttons.search')}}</button>
                        </div>

                    </div>
                </form>
            </div>
        </div>
    </section>    
</template>

<script>
export default {
   name:"CompactSearch"
}
</script>

<style scoped>

select {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background-size: 13px;
}

[dir=ltr] select{
  background: url("../../../public/assets/img/icons/caret.webp") 95% / 15% no-repeat #eee;
  padding: .375rem 2.25rem .375rem .75rem;
}

[dir=rtl] select{
  background: url("../../../public/assets/img/icons/caret.webp") 6% / 9% no-repeat #eee;
  padding: .375rem .75rem .375rem 2.25rem;
}

.banner_layer{
    background: #131654cc;
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;
    padding: 250px 0 70px;
}

.find-form {
  position: relative;
  z-index: 10;
}
.find-form .findfrom-wrapper {
  background: #fff;
  padding: 45px 30px;
  max-width: 100%;
  margin: 0 auto;
  -webkit-box-shadow: 0 0 64px rgba(17, 17, 17, 0.1);
  box-shadow: 0 0 64px rgba(17, 17, 17, 0.1);
  border-radius: 7px;
}
.find-form .findfrom-wrapper .find-btn a {
  text-align: center;
  display: block;
  padding: 13px 0;
}
.find-form .findfrom-wrapper .find-btn a i {
  font-size: 20px;
}
.find-form .findfrom-wrapper .find-btn a i::before {
  vertical-align: middle;
  margin-right: 10px;
}
.find-form .findfrom-wrapper input,
.find-form .findfrom-wrapper select {
  font-weight: 300;
  font-size: 13px;
  line-height: 16px;
  color: #939393;
  height: 45px;
  background-color: #fff;
}
.find-form .findfrom-wrapper input:focus,
.find-form .findfrom-wrapper select:focus {
  -webkit-box-shadow: none;
  box-shadow: none;
}
.find-form .findfrom-wrapper input option,
.find-form .findfrom-wrapper select option {
  font-size: 16px;
  line-height: 20px;
  color: #454545;
  padding: 10px;
}
.find-form .findfrom-wrapper input, .find-form .findfrom-wrapper select,
.find-form .findfrom-wrapper input[type="date"] {
  border: none;
  border-radius: 0;
}

.find-form .input-group{
    border: 1px solid #c6c7c8;
}
.find-form .findfrom-wrapper input::-webkit-input-placeholder,
.find-form .findfrom-wrapper input[type="date"]::-webkit-input-placeholder {
  font-weight: 300;
  font-size: 13px;
  line-height: 16px;
  color: #939393;
}
.find-form .findfrom-wrapper input:-ms-input-placeholder,
.find-form .findfrom-wrapper input[type="date"]:-ms-input-placeholder {
  font-weight: 300;
  font-size: 13px;
  line-height: 16px;
  color: #939393;
}

html[lang="ar"] .input-group>:not(:first-child):not(.dropdown-menu):not(.valid-tooltip):not(.valid-feedback):not(.invalid-tooltip):not(.invalid-feedback){
    margin-left: auto;
    margin-right: -1px;
}
.find-form .findfrom-wrapper input::-ms-input-placeholder,
.find-form .findfrom-wrapper input[type="date"]::-ms-input-placeholder {
  font-weight: 300;
  font-size: 13px;
  line-height: 16px;
  color: #939393;
}
.find-form .findfrom-wrapper input::placeholder,
.find-form .findfrom-wrapper input[type="date"]::placeholder {
  font-weight: 300;
  font-size: 13px;
  line-height: 16px;
  color: #939393;
}
.find-form .findfrom-wrapper input:focus,
.find-form .findfrom-wrapper input[type="date"]:focus {
  -webkit-box-shadow: none;
  box-shadow: none;
}

.find-form .findfrom-wrapper label{
    color: #113c64;
    font-weight: 500;
    text-align: initial;
    width: 100%;
    margin-bottom: 7px;
    white-space: nowrap;
    font-size: 0.9rem;
}

.find-form .input-group-text{
    color: #939393;
    background: transparent;
    border: none;
}

.btn-primary{
    height: 45px;
    border-radius: 0;
    background: #131654;
    border: 1px solid #131654;
    width: 100%;
}

.btn-primary:hover,
.btn-primary:focus{
    background: #fcca01;
    border-color: #fcca01;
    color: #113c64;
}


</style>