const dateTimeFormats = {
  en: {
    short: { hour: "numeric", minute: "numeric" },
    minshort: { year: "numeric", month: "numeric", day: "numeric" }
  },
  ar: {
    short: { hour: "numeric", minute: "numeric" },
    minshort: { year: "numeric", month: "numeric", day: "numeric" }

  }
}

export default dateTimeFormats
