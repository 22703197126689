<template>
  <main id="main">
    <section class="hotels_section p-0">
      <div class="banner_layer">
        <h1 class="text-capitalize text-white">{{$t('header.menu.driving_license')}}</h1>
      </div>     
    </section>

    <section class="ecommerce">
      <div class="container vue-form-wizard">  
        <b-card class="shadow_card">   
          <b-form id="driving_licence_form" @submit="onSubmit" @reset="onReset" class="wizard-tab-content driving_licence_form row">
            <b-form-group
              :label="$t('form.fname')"
              label-for="input-1"
              class="col-md-6 mb-2"
            >
              <b-form-input
                id="first-name"
                v-model="form.fname"
                type="text"
                placeholder="John"
                required
              ></b-form-input>
            </b-form-group>

            <b-form-group
              :label="$t('form.lname')"
              label-for="input-1"
              class="col-md-6 mb-2"
            >
              <b-form-input
                id="last-name"
                v-model="form.lname"
                type="text"
                placeholder="Doe"
                required
              ></b-form-input>
            </b-form-group>

            <b-form-group
              :label="$t('form.mailId')"
              label-for="email"
              class="col-md-6 mb-2"
            >
              <b-form-input
                id="email"
                v-model="form.email"
                type="email"
                placeholder="john.doe@gmail.com"
                required
              ></b-form-input>
            </b-form-group>

            <b-form-group  :label="$t('visa.step1.country')" label-for="country" class="col-md-6 mb-2">
              <v-select
                id="country"
                v-model="form.country"
                :options="countries"
                :dir="'isRTL'"
                :selectable="option => ! option.text.includes('select_value')" 
                label="text"
                required
              ></v-select>
            </b-form-group>

             <b-form-group :label="$t('visa.step1.lang')" label-for="language" class="col-md-6 mb-2">
              <v-select
                id="language"
                v-model="form.lang"
                :options="translated(languages)"
                label="text"
                :dir="'isRTL'"
                :selectable="option => ! option.text.includes('select_value')" 
                required
              ></v-select>
            </b-form-group>
             <b-form-group :label="$t('visa.step1.uploadDriving')" label-for="nationalId" class="col-md-6 mb-2">
               <b-form-file
                  v-model="form.nationalId"
                  :state="Boolean(form.nationalId)"
                  :placeholder="$t('visa.step4.pinfo.hold1')"
                  :drop-placeholder="$t('visa.step4.pinfo.hold2')"
                  :dir="this.$route.params.locale =='ar'?'rtl':'ltr'"
                ></b-form-file>
             </b-form-group>
            <div class="d-flex align-items-center justify-content-between mt-4">

              <b-button type="reset" variant="danger">{{$t("form.reset")}}</b-button>
              <b-button type="submit" variant="primary">{{$t("form.submit")}}</b-button>
              
            </div>
            
          </b-form>
        </b-card>  
      </div>
    </section>
    <Newsletter />
</main>
</template>
          
<script>
import http from "../services/api";
import Newsletter from "@/components/partials/Newsletter";
import "vue-form-wizard/dist/vue-form-wizard.min.css";
import VueIziToast from 'vue-izitoast';
import 'izitoast/dist/css/iziToast.css';

  export default {
    name:"DrivingLicence",
    components:{
        Newsletter,
           // eslint-disable-next-line vue/no-unused-components
    VueIziToast,
            },
    data() {
      return {
        form: {
          fname: '',
          lname: '',
          email:'',
          country: null,
          lang:null,
          nationalId:null
        },
        countries: [],
          languages: [
          { value: "-1", text: {"en":"Please select...","ar":"الرجاء تحديد ..."} },
          { text: {"en":"Arabic","ar":"العربية"}, value: 1 },
          { text: {"en":"English","ar":"الإنجليزية"}, value: 2 },
          { text: {"en":"French","ar":"الفرنسية"}, value: 3 },
          ],
      }
    },
    created() {
      http.get(`/get/pays`).then((response) => {
        this.paysData = response.data.data;
        this.updateCountriesSelect(response.data.data)
      });
      this.isRtl = this.getLocale();
    },
    methods: {
      getLocale() {
        const locale = this.$route.params.locale == "ar" ? "rtl" : "ltr";
        return locale;
      },
       updateCountriesSelect(data) {
        data.forEach(elem => {
          this.countries.push({
            "value": elem.id.toString(),
            "text": elem.name
          })
        })
      },
      onSubmit(event) {
        event.preventDefault()
        const formData = new FormData();
        formData.append('fName', this.form.fname);
        formData.append('lName', this.form.lname);
        formData.append('email', this.form.email);
        formData.append('country', this.form.country.text);
        formData.append('lang', this.form.lang.value);
        formData.append('nationalID', this.form.nationalId);
        
        const headers = { 'Content-Type': 'multipart/form-data' };
        if (localStorage.getItem('jwt') == null) {
          this.$router.push('Login')
        }else{
          http.post('/drivingLicence_store', formData, { headers }).then((res) => {
            res.data.files; // binary representation of the file
            res.status; // HTTP status
            if(res.data.status){
              this.$toast.success(res.data.message);
              this.$destroy();
              this.$router.push(`/`)
            }else{
                this.$toast.error('An error has occured');
            }
            this.form.fname = ''
          this.form.lname = ''
          this.form.email = ''
          this.country=null,
          this.lang=null,
          this.form.nationalId = null
          });
        }
      },
      onReset(event) {
        event.preventDefault()
        // Reset our form values
        this.form.fname = ''
        this.form.lname = ''
        this.form.email = ''
        this.country=null,
        this.lang=null,
        this.form.nationalId = null
      },
      translated(data){
        const locale = this.$route.params.locale;
        let final = [];
        data.forEach(element => {
         final.push({
           value:element.text[locale],
           text:element.text[locale]
         })
        });
        return final;
      },     
    }
  }
</script>
<style lang="scss" scoped>

.file {
  position: relative;
  display: inline-block;
  height: 2.5rem;
  cursor: pointer;
}
.file input {
  min-width: 14rem;
  margin: 0;
  filter: alpha(opacity=0);
  opacity: 0;
}
.file-custom {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  z-index: 5;
  height: 2.5rem;
  padding: .5rem 1rem;
  line-height: 1.5;
  color: #555;
  user-select: none;
  background-color: #fff;
  border: .075rem solid #ddd;
  border-radius: .25rem;
  box-shadow: inset 0 .2rem .4rem rgba(0,0,0,.05);
}
.file-custom:after {
  content: "Choose file...";
}
.file-custom:before {
  position: absolute;
  top: -.075rem;
  right: -.075rem;
  bottom: -.075rem;
  z-index: 6;
  display: block;
  height: 2.5rem;
  padding: .5rem 1rem;
  line-height: 1.5;
  color: #555;
  content: "Browse";
  background-color: #eee;
  border: .075rem solid #ddd;
  border-radius: 0 .25rem .25rem 0;
}

// Focus state
.file input:focus ~ .file-custom {
  box-shadow: 0 0 0 .075rem #fff, 0 0 0 .2rem #0074d9;
}

.banner_layer{
    background: #131654cc;
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;
    padding: 200px 0 120px;
}
.hotels_section{
  background: url(../../public/assets/img/banners/flights.webp) center no-repeat;
}
 ul {
    list-style-type: none;
  }
</style>