import api from './api';

class UserService {

  getUserBoard() {
    return api.get('/profile');
  }
  updateUser({ name_en, name_ar, email, password, password_confirmation, current_password }) {
    return api.post("/user/updated/password", {
      name_en,
      name_ar,
      email,
      password,
      password_confirmation,
      current_password
    });
  }
}

export default new UserService();
