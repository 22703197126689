<template>
  <div id="app">
    <Entete />
    <router-view />
    <Partners />
    <Footer />
  </div>
</template>

<script>
import EventBus from "@/EventBus"
import Entete from '@/components/partials/Header';
import Footer from '@/components/partials/Footer';
import Partners from '@/components/partials/Partners'
export default {
  name: 'App',
  components: {
    Entete,
    Partners,
    Footer
  },
   data: () => ({
      isLoading: true
    }),

  mounted() {
      EventBus.$on("i18n-load-start", () => (this.isLoading = true))

      EventBus.$on("i18n-load-complete", () => (this.isLoading = false))
    },
}
</script>

<style>
#app {

  text-align: center;
  color: #454545;
}

html[lang="en"] #app {
font-family: 'Montserrat', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html[lang="ar"] #app {
font-family: 'El Messiri', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
</style>
