<template>
  <div>
    <nav class="nowrapp">
      <div class="nav nav-tabs" id="nav-tab" role="tablist">
        <a class="nav-link" id="nav-oneway" data-bs-toggle="tab" href="#nav-onewaytab" role="tab" aria-controls="nav-home" aria-selected="true">{{$t('buttons.oneway')}}
        </a>
        <a class="nav-link active" id="nav-roundTrip" data-bs-toggle="tab" href="#nav-roundTripTab" role="tab" aria-controls="nav-profile" aria-selected="false">{{$t('buttons.roundtrip')}}
        </a>
        <a class="nav-link" id="nav-multicity" data-bs-toggle="tab" href="#nav-multicityTab" role="tab" aria-controls="nav-contact" aria-selected="false">{{$t('buttons.multicity')}}
        </a>
      </div>
    </nav>
    <div class="tab-content compact" id="nav-tabContent">
      <div class="tab-pane fade" id="nav-onewaytab" role="tabpanel" aria-labelledby="nav-oneway">
        <form  class="form-inline reservation-form" method="post" name="reservationform">
          <div class="row py-2 align-items-center">
            <div class="col-md-4 step-where d-flex align-items-center">
              <div class="form-group">
                <div class="content-checkin-data">
                  <label>                 
                    {{$t("booking.from")}}
                  </label>
                  <div class="input-group">
                                <div class="input-group-text">
                                   <img src="../../../public/assets/img/icons/location.webp" alt="Rahalatna" /> 
                                </div>
                                <input type="text" class="form-control" 
                                id="destination" 
                                :placeholder="$t('placeholders.destination')">
                            </div>
                </div>
              </div>
              <div class="icon px-2">
                <i class="fal fa-exchange"></i>   
              </div>
             
              <div class="form-group">
                <div class="content-checkin-data">
                  <label>               
                   {{$t("booking.to")}}
                  </label>
                  <div class="input-group">
                                <div class="input-group-text">
                                   <img src="../../../public/assets/img/icons/location.webp" alt="Rahalatna" /> 
                                </div>
                                <input type="text" class="form-control" 
                                id="destination" 
                                :placeholder="$t('placeholders.destination')">
                            </div>
                </div>
              </div>
            </div>

            <div class="col-md-2">
             <div class="form-group">
                <div class="content-checkin-data">
                  <label>               
                   {{$t("booking.dep")}}
                  </label>
                  <div class="input-group">
                    <div class="input-group-text">
                      <img src="../../../public/assets/img/icons/calendar.webp" alt="Rahalatna" /> 
                     </div>
                     <input type="date" class="form-control" id="checkOut" placeholder="10/07/2021">
                  </div>
                </div>
            </div>
            </div>

            <div class="col-md-2">
             <div class="form-group">
                <div class="content-checkin-data">
                  <label>               
                   {{$t("booking.ret")}}
                  </label>
                  <div class="input-group">
                    <div class="input-group-text">
                      <img src="../../../public/assets/img/icons/calendar.webp" alt="Rahalatna" /> 
                     </div>
                     <input type="date" class="form-control" id="checkOut" placeholder="10/07/2021">
                  </div>
                </div>
            </div>
            </div>
            
             <div class="col-md-2">
               <div class="form-group">
                <div class="content-checkin-data">
                    <label for="noNights">{{$t('booking.class')}}</label>
                            <div class="input-group">
                                <div class="input-group-text">
                                   <img src="../../../public/assets/img/icons/user.webp" alt="Rahalatna" /> 
                                </div>
                                <select class="form-select inputstl" id="noNights" name="noNights">
                                    <option selected>{{$t('placeholders.guest')}}</option>
                                    <option value="1">One</option>
                                    <option value="2">Two</option>
                                    <option value="3">Three</option>
                                </select>
                            </div>
                </div>
               </div>
             </div>
                        <div class="col">
                            <label>&nbsp;</label>
                            <button type="submit" class="btn btn-primary">{{$t('buttons.changeSearch')}}</button>
                        </div>
           
          </div>
        </form>
      </div>
      <div class="tab-pane fade show active" id="nav-roundTripTab" role="tabpanel" aria-labelledby="nav-roundTrip">
         <form  class="form-inline reservation-form" method="post" name="reservationform">
          <div class="row py-2 align-items-center">
            <div class="col-md-4 step-where d-flex align-items-center">
              <div class="form-group">
                <div class="content-checkin-data">
                  <label>                 
                    {{$t("booking.from")}}
                  </label>
                  <div class="input-group">
                                <div class="input-group-text">
                                   <img src="../../../public/assets/img/icons/location.webp" alt="Rahalatna" /> 
                                </div>
                                <input type="text" class="form-control" 
                                id="destination" 
                                :placeholder="$t('placeholders.destination')">
                            </div>
                </div>
              </div>
              <div class="icon px-2">
                <i class="fal fa-exchange"></i>   
              </div>
             
              <div class="form-group">
                <div class="content-checkin-data">
                  <label>               
                   {{$t("booking.to")}}
                  </label>
                  <div class="input-group">
                                <div class="input-group-text">
                                   <img src="../../../public/assets/img/icons/location.webp" alt="Rahalatna" /> 
                                </div>
                                <input type="text" class="form-control" 
                                id="destination" 
                                :placeholder="$t('placeholders.destination')">
                            </div>
                </div>
              </div>
            </div>

            <div class="col-lg-2 col-md-3 col-6">
             <div class="form-group">
                <div class="content-checkin-data">
                  <label>               
                   {{$t("booking.dep")}}
                  </label>
                  <div class="input-group">
                    <div class="input-group-text">
                      <img src="../../../public/assets/img/icons/calendar.webp" alt="Rahalatna" /> 
                     </div>
                     <input type="date" class="form-control" id="checkOut" placeholder="10/07/2021">
                  </div>
                </div>
            </div>
            </div>

            <div class="col-lg-2 col-md-3 col-6">
             <div class="form-group">
                <div class="content-checkin-data">
                  <label>               
                   {{$t("booking.ret")}}
                  </label>
                  <div class="input-group">
                    <div class="input-group-text">
                      <img src="../../../public/assets/img/icons/calendar.webp" alt="Rahalatna" /> 
                     </div>
                     <input type="date" class="form-control" id="checkOut" placeholder="10/07/2021">
                  </div>
                </div>
            </div>
            </div>
            
             <div class="col-md-2 col-6">
              <div class="form-group">
                            <label for="noNights">{{$t('booking.class')}}</label>
                            <div class="input-group">
                                <div class="input-group-text">
                                   <img src="../../../public/assets/img/icons/user.webp" alt="Rahalatna" /> 
                                </div>
                                <select class="form-select inputstl" id="noNights" name="noNights">
                                    <option selected>{{$t('placeholders.guest')}}</option>
                                    <option value="1">One</option>
                                    <option value="2">Two</option>
                                    <option value="3">Three</option>
                                </select>
                            </div>
                        </div>  
                        </div>
                        <div class="col">
                            <label>&nbsp;</label>
                            <button type="submit" class="btn btn-primary">{{$t('buttons.changeSearch')}}</button>
                        </div>
           
          </div>
          <div class="row filter">
              <div class="col-md-4 col-xs-6 w-50">
                  <input type="checkbox" class="form-check-input">
                  <label for="" class="grey">
                      {{$t("booking.nonStop")}}
                  </label>
              </div>

              <div class="col-md-4 col-xs-6 w-50">
                  <input type="checkbox" class="form-check-input">
                  <label for="" class="grey">
                      {{$t("booking.flexible")}}
                  </label>
              </div>
          </div>
        </form>
      </div>
      <div class="tab-pane fade" id="nav-multicityTab" role="tabpanel" aria-labelledby="nav-multicity">
         <form  class="tab-pane fade show active form-inline reservation-form" method="post" name="reservationform">
          <div class="row py-2 align-items-center">
            <div class="col-md-4 step-where d-flex align-items-center">
              <div class="form-group">
                <div class="content-checkin-data">
                  <label>                 
                    {{$t("booking.from")}}
                  </label>
                  <div class="input-group">
                                <div class="input-group-text">
                                   <img src="../../../public/assets/img/icons/location.webp" alt="Rahalatna" /> 
                                </div>
                                <input type="text" class="form-control" 
                                id="destination" 
                                :placeholder="$t('placeholders.destination')">
                            </div>
                </div>
              </div>
              <div class="icon px-2">
                <i class="fal fa-exchange"></i>   
              </div>
             
              <div class="form-group">
                <div class="content-checkin-data">
                  <label>               
                   {{$t("booking.to")}}
                  </label>
                  <div class="input-group">
                                <div class="input-group-text">
                                   <img src="../../../public/assets/img/icons/location.webp" alt="Rahalatna" /> 
                                </div>
                                <input type="text" class="form-control" 
                                id="destination" 
                                :placeholder="$t('placeholders.destination')">
                            </div>
                </div>
              </div>
            </div>

            <div class="col-md-2">
             <div class="form-group">
                <div class="content-checkin-data">
                  <label>               
                   {{$t("booking.dep")}}
                  </label>
                  <div class="input-group">
                    <div class="input-group-text">
                      <img src="../../../public/assets/img/icons/calendar.webp" alt="Rahalatna" /> 
                     </div>
                     <input type="date" class="form-control" id="checkOut" placeholder="10/07/2021">
                  </div>
                </div>
            </div>
            </div>

            <div class="col-md-2">
             <div class="form-group">
                <div class="content-checkin-data">
                  <label>               
                   {{$t("booking.ret")}}
                  </label>
                  <div class="input-group">
                    <div class="input-group-text">
                      <img src="../../../public/assets/img/icons/calendar.webp" alt="Rahalatna" /> 
                     </div>
                     <input type="date" class="form-control" id="checkOut" placeholder="10/07/2021">
                  </div>
                </div>
            </div>
            </div>
            
             <div class="col-sm-2">
                            <label for="noNights">{{$t('booking.class')}}</label>
                            <div class="input-group">
                                <div class="input-group-text">
                                   <img src="../../../public/assets/img/icons/user.webp" alt="Rahalatna" /> 
                                </div>
                                <select class="form-select inputstl" id="noNights" name="noNights">
                                    <option selected>{{$t('placeholders.guest')}}</option>
                                    <option value="1">One</option>
                                    <option value="2">Two</option>
                                    <option value="3">Three</option>
                                </select>
                            </div>
                        </div>
                        <div class="col">
                            <label>&nbsp;</label>
                            <button type="submit" class="btn btn-primary">{{$t('buttons.changeSearch')}}</button>
                        </div>
           
          </div>
        </form>
      </div>
    </div>
  </div>
</template> 

<script>
import http from "../../services/api";
export default {
    name:"CompactBooking",
    data() {
      return{
        pays:[]
      }
    },
    methods:{
      getPays() {        
        http.get(`/get/pays`)
          .then(res => {
              this.pays = res.data.data;
              console.log(res)            
          });
        },
    },
    created(){
      // this.getPays()
    }
}
</script>

<style scoped>
input::-webkit-input-placeholder{
     color: #999!important;
    font-size: 13px!important;
}
.reservation{
    position: absolute;
    top: 55%;
    left: 10%;
    width: 100%;
    z-index: 6000;
    margin: auto;
}

.reserve .tab-content{
    background: #fff;
    padding: 15px;
}
.nowrapp .nav{
  flex-wrap: nowrap;
}
nav{
    background: #131654;
    padding: 10px 15px;
   
}

.form-select{
    border-radius: 0;
}
.icon{
    line-height: 80px;
    height: 50px;
    color:#c6c7c8;
}
.nav-tabs .nav-link{
 color: #fff !important;
 border: 1px solid #fff;
 
 border-radius: 0;
}

html[lang="en"] .nav-tabs .nav-link{
margin-right: 7px;
}
html[lang="ar"] .nav-tabs .nav-link{
margin-left: 7px;
}
.nav-tabs{
    border:none;
}

.nav-tabs .nav-item.show .nav-link, .nav-tabs .nav-link.active, .nav-tabs .nav-link:hover{
    color: #131654 !important;
    transition: 0.5s all ease-in-out;
    background: #fff;
}

.reservation-form .form-group label{
    color: #131654;
    text-align: initial;
    display: block;
    font-weight: 500;
    font-size: 13px;
    white-space: nowrap;
    margin-bottom: 7px;
    margin-top:7px;
}

.reservation-form.input-group-text{
    color: #939393;
    background: transparent;
    border: none;
}

.reservation-form .btn-primary{
    height: 42px;
    border-radius: 0;
    background: #131654;
    border: 1px solid #131654;
    white-space: nowrap;
    font-size: 13px;
    width:100%;
}

.reservation-form .btn-primary:hover,
.reservation-form .btn-primary:focus{
    background: #fcca01;
    border-color: #fcca01;
    color: #113c64;
}


.input-group-text {
    color: #939393;
    background: transparent;
    border: none;
}

.input-group{
    border: 1px solid #c6c7c8;
}

.reservation-form  input,.reservation-form  select, 
.reservation-form  input {
    border: none;
    border-radius: 0;
}

.tab-content.compact{
    padding: 15px;
}

.filter .col-md-4{
    display: flex;
    align-items: center;
}
.filter .form-check-input {
    width: 1em;
    height: 1em;
    margin-top: 0;
    vertical-align: top;
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    border: 1px solid rgba(0,0,0,.25);
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    -webkit-print-color-adjust: exact;
    color-adjust: exact;
}

html[lang="en"] .form-check-input {
    margin-right: 7px;
}

html[lang="ar"] .form-check-input {
    margin-left: 7px;
}
label.grey{
    color:#939393;
}
@media screen and (max-width:767px) {
nav{
    padding:0
  }
  .reserve{
    margin-top:25px;
  }
}

[dir=ltr] select{
  background: url("../../../public/assets/img/icons/caret.webp") 95% / 15% no-repeat #fff;
  padding: .375rem 2.25rem .375rem .75rem;
  background-size: 12px;
}

[dir=rtl] select{
  background: url("../../../public/assets/img/icons/caret.webp") 6% / 9% no-repeat #fff;
  padding: .375rem .75rem .375rem 2.25rem;
  background-size: 12px;
}
</style>