import store from "../store";
import api from "./api";

import TokenService from "./token.service";

class AuthService {
  login({ email, password }) {
    return api
      .post("/login", {
        email,
        password
      })
      .then((response) => {
        if (response.data.accessToken) {
          store.commit('setMyName',response.data)
          TokenService.setUser(response.data);
        }

        return response.data;
      });
  }

  logout() {
    TokenService.removeUser();
  }

  register({ name_en, name_ar, email, password, password_confirmation }) {
    return api.post("/register", {
      name_en,
      name_ar,
      email,
      password,
      password_confirmation
    });
  }
}

export default new AuthService();
