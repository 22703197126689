<template>
    <div>
        <div class="login_wrap widget-taber-content p-30 background-white border-radius-5">
            <div class="padding_eight_all bg-white">
                <div class="heading_s1">
                    <h3 class="mb-30">{{$t("account.create")}}</h3>
                </div>
                <p class="mb-50 font-sm">
                    {{$t("account.privacy")}}
                </p>
                <form @submit.prevent="handleSubmit">
                    <div class="form-group mb-3">
                        <input type="text" autofocus v-model="form.name_en" name="name_en"
                         class="form-control"  
                            :class="{ 'is-invalid': isSubmitted && $v.form.name_en.$error }" :placeholder="$t('account.n')">
                        <div v-if="isSubmitted && !$v.form.name_en.required" class="invalid-feedback">
                           {{$t("form.validation.nreq")}}</div>
                    </div>
                  
                    <div class="form-group mb-3">
                        <input type="text" autofocus v-model="form.email" name="email"
                         class="form-control"  
                            :class="{ 'is-invalid': isSubmitted && $v.form.email.$error }" :placeholder="$t('account.mail')">
                        <div v-if="isSubmitted && $v.form.email.$error" class="invalid-feedback">
                            <span v-if="!$v.form.email.required">{{$t("form.validation.emailreq")}}</span>
                            <span v-if="!$v.form.email.email">{{$t("form.validation.emailvalid")}}</span>
                        </div>
                    </div>

                      <div class="form-group mb-3">
                        <input type="text" autofocus v-model="form.phone" name="phone"
                         class="form-control"  
                            :class="{ 'is-invalid': isSubmitted && $v.form.phone.$error }" :placeholder="$t('form.tel')">
                        <div v-if="isSubmitted && !$v.form.phone.required" class="invalid-feedback">{{$t("form.validation.fnreq")}}</div>
                    </div>

                    <div class="form-group mb-3">
                        <input type="password" v-model="form.password"
                         class="form-control"  
                            :class="{ 'is-invalid': isSubmitted && $v.form.password.$error }" name="password"
                            :placeholder="$t('account.pwd')">
                        <div v-if="isSubmitted && $v.form.password.$error" class="invalid-feedback">
                            <span v-if="!$v.form.password.required">{{$t("form.validation.pwdreq")}}</span>
                            <span v-if="!$v.form.password.minLength">{{$t("account.fivelong")}}</span>
                        </div>
                    </div>
                    <div class="form-group mb-3">
                        <input v-model="form.password_confirmation"
                         class="form-control"  
                            :class="{ 'is-invalid': isSubmitted && $v.form.password_confirmation.$error }"
                            type="password" name="password" :placeholder="$t('form.validation.pwdconfreq')">
                        <div v-if="isSubmitted && $v.form.password_confirmation.$error" class="invalid-feedback">
                            <span v-if="!$v.form.password_confirmation.required">{{$t("form.validation.pwdconfreq")}}</span>
                            <span v-else-if="!$v.form.password_confirmation.sameAsPassword">{{$t("form.validation.pwdmatch")}}</span>
                        </div>
                    </div>
                    <div class="login_footer form-group mb-3">
                        <div class="chek-form">
                            <div class="custome-checkbox">
                                <input class="form-check-input" v-model="form.accept" @change="$v.form.accept.$touch()"
                                    type="checkbox" name="checkbox" id="exampleCheckbox12" value="">&nbsp;
                                <label class="form-check-label"
                                    :class="{ 'is-invalid': isSubmitted && $v.form.accept.$error }"
                                    for="exampleCheckbox12"><span>{{$t("account.agree")}}</span></label>&nbsp;
                                <div v-if="isSubmitted && $v.form.accept.$error" class="invalid-feedback">
                                    <span v-if="!$v.form.accept.required">{{$t("form.validation.acpt")}}</span>
                                </div>
                            </div>
                        </div>
                        <!-- <LocalizedLink :to="'privacy-policy'">
                            <i class="fi-rs-book-alt mr-5 text-muted"></i>
                            {{$t("account.more")}}
                        </LocalizedLink> -->
                    </div>
                    <div class="form-group mt-5">
                        <button type="submit" class="btn btn-primary w-100 btn-block" name="login">{{$t("account.reg")}}</button>
                    </div>
                    <span v-show="elementVisible" class="hideElement" v-bind:style="style"> {{message}} </span>
                </form>

                <!-- <div class="divider-text-center mt-15 mb-15">
                    <span> or</span>
                </div>
                <ul class="btn-login list_none text-center mb-15">
                    <li><a href="#" class="btn btn-facebook hover-up mb-lg-0 mb-sm-4">Login With Facebook</a></li>
                    <li><a href="#" class="btn btn-google hover-up">Login With Google</a></li>
                </ul> -->
                <!-- <div class="text-muted text-center">Already have an account? <a href="#">Sign in now</a></div> -->
            </div>
        </div>
    </div>
</template>


<script>
    // import LocalizedLink from '@/components/partials/LocalizedLink';
    import {
        required,
        email,
        minLength,
        sameAs
    } from "vuelidate/lib/validators";
    export default {
        name: "Register",
        // components: {
        //     LocalizedLink,
        // },
        data() {
            return {
                elementVisible: false,
                style: {
                    color: '',
                },
                message: '',
                form: {
                    name_en: "",
                    phone: "",
                    email: "",
                    password: "",
                    password_confirmation: "",
                    accept: "",
                },
                isSubmitted: false,
                ListRouteComeBack:[
                    'IDTranslation',
                    'VisaFilling',
                    'DrivingLicence'
                ]
            }
        },
        validations: {
            form: {
                email: {
                    required,
                    email
                },
                password: {
                    required,
                    minLength: minLength(5)
                },
                password_confirmation: {
                    required,
                    sameAsPassword: sameAs('password')
                },
                name_en: {
                    required,
                },
                phone: {
                    required,
                },
                accept: {
                    required(val) {
                        return val
                    }
                }
            },
        },
        created() {

        },
        computed: {
            loggedIn() {
                return this.$store.state.auth.status.loggedIn;
            }
        },
        mounted() {
            if (this.loggedIn) {
                this.$router.push('dashboard');
            }
        },
        methods: {
            handleSubmit() {
                this.isSubmitted = true;

                this.$v.form.$touch();
                if (this.$v.form.$invalid) {
                    return;
                }
                this.Submit(this.form)
            },
            Submit(form) {

                this.$store.dispatch('auth/register', form).then(
                    data => {
                        this.style.color = 'green';
                        this.message = data.message
                        setTimeout(() => this.elementVisible = true, 0)
                        this.$store.dispatch('auth/login', form).then(
                        () => {
                            this.ListRouteComeBack.includes(localStorage.getItem('LS_ROUTE_KEY')) ? this.$router.go(-1) :this.$router.push('dashboard');
                        })
                    },
                    error => {
                        this.style.color = 'red';
                        this.message =error.response.data.email[0];
                        setTimeout(() => this.elementVisible = true, 0)

                    }
                );
            }

        }
    };
</script>

<style lang="scss" scoped>
.mb-30{
    margin-bottom: 30px;
}

</style>