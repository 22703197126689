import Vue from "vue";
import Vuex from "vuex";
import { auth } from './auth.module';
import createPersistedState from "vuex-persistedstate";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    lang: localStorage.getItem('X-localization'),
    myname:'',
    notifications: []
  },
  mutations: {
    setMyName: function(state,myname){
      state.myname = myname.userData.name
    },
    reset_setMyName: function(state){
      state.myname= '';
    },
    UpdateSuccess(state, user) {
      state.auth.user.name = user.name;
      state.auth.user.email = user.email;
      state.myname = user.name
    },
    PUSH_NOTIFICATION(state, notification){
      state.notifications.push({
          ...notification,
          id: (Math.random().toString(36) + Date.now().toString(36)).substr(2)
      })
    },
  
    REMOVE_NOTIFICATION(state, notificationToRemove){
      state.notifications = state.notifications.filter(notification => {
          return notification.id != notificationToRemove.id;
      })
    }
  },
  actions: {

    //notifications
     addNotification({ commit }, notification){
      commit('PUSH_NOTIFICATION', notification);
    },
  
    removeNotification({ commit }, notification){
      commit('REMOVE_NOTIFICATION', notification);
    }
    
  },
  getters:{
    getMyName: state => state.myname
  },

  plugins: [createPersistedState()],
    
  modules: {
    auth,
  }
});