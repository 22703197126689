<template>
  <header id="header" class="fixed-top align-items-center" @scroll="becomeSticky" ref="mainHeader">
    <Nav/>
  </header>

</template>

<script>
import Nav from '@/components/partials/Nav'

export default {
  name: "Entete",
  components:{
    Nav
  },
  mounted() {
            this.$nextTick(() => {
                window.addEventListener('scroll', this.becomeSticky);
            });
        },
  methods:{
    becomeSticky() {
                    const mainHeader = this.$refs.mainHeader;
                    const sticky = mainHeader.offsetTop;
                    window.pageYOffset > sticky ? mainHeader.classList.add('header-scrolled') : mainHeader.classList.remove('header-scrolled');
        }
  }
}
</script>

<style scoped>
#sideBarMenu{
  position: absolute;
  top: 0;
  left: 0;
  width: 250px;
  background: #131654;
  height: 100vh;
  padding: 30px 0px;
  transition: 0.4s all ease-in;
}

#sideBarMenu ul{
  list-style-type:none;
  text-align: initial;
}

#sideBarMenu ul li a{
  color:#fff;
  font-weight: 500;
  
}

@media screen and (max-width:767px){
  #header{
    padding: 0;
  }
}

</style>