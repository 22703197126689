import Vue from 'vue'
import App from './App.vue'
import VueRouter from 'vue-router'
import router from './router'
import i18n from './i18n'
import VueAwesomeSwiper from 'vue-awesome-swiper';
import VueMeta from 'vue-meta';
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'
import axios from 'axios'
import VueAxios from 'vue-axios'
import vSelect from 'vue-select';
import VueFlatPickr from 'vue-flatpickr-component';
import 'flatpickr/dist/flatpickr.css';
import VueIziToast from 'vue-izitoast';
import store from "./store";
import setupInterceptors from './services/setupInterceptors';
import Vuelidate from 'vuelidate';
import VueAnalytics from 'vue-analytics';

import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
//intercept refresh token
setupInterceptors(store);
//intercept refresh token
Vue.use(VueIziToast);
Vue.component("v-select", vSelect);
Vue.use(VueAxios, axios)
Vue.use(BootstrapVue)
Vue.use(IconsPlugin) 
Vue.use(VueAwesomeSwiper, /* { default options with global component } */)
Vue.use(VueMeta);
// Vue.config.productionTip = true;
// Vue.config.devtools = false;
// Vue.config.debug = false;
// Vue.config.silent = true;
Vue.use(VueRouter)
Vue.use(VueFlatPickr);
Vue.use(Vuelidate);
Vue.use(VueAnalytics, {
  id: 'UA-243355484-1',
  router
});

new Vue({
  router,
  i18n,
  store,
  render: h => h(App)
}).$mount('#app')
