<template>
  <main id="main">
    <section class="hotels_section p-0">
      <div class="banner_layer">
        <h1 class="text-capitalize text-white">{{$t('header.menu.visa_filling')}}</h1>
      </div>
    </section>
    <section class="ecommerce visa_filling">
      <div class="container">
        <div class="row pb-5">
          <form-wizard color="#131654" :title="null" :subtitle="null" shape="square"
            :finish-button-text="$t('form.submit')" :back-button-text="$t('visa.step1.prev')"
            :next-button-text="$t('visa.step1.next')" class="mb-3" @on-complete="formSubmitted" id="formWizard">
            <!--country selection -->
            <tab-content :title="$t('visa.step1.title')" :before-change="validateAsyncCountry">
              <validation-observer ref="countryRules" tag="form">
                <b-row>
                  <b-col cols="12" class="mb-2">
                    <h5 class="mb-0">
                      {{$t('visa.step1.title')}}
                    </h5>
                    <small class="text-muted"> {{$t('visa.step1.desc')}}</small>
                  </b-col>
                  <b-col md="12">
                    <validation-provider #default="{ errors }" name="Country" rules="required">
                      <b-form-group :label="$t('visa.step1.country')" label-for="country" class="is_required"
                        :state="errors.length > 0 ? false:null">
                        <v-select id="country" v-model="selectedContry" :options="countries" :dir="'isRTL'"
                          :selectable="option => ! option.value.includes('select_value')" label="text"
                          v-on:input="setCountry" />
                        <b-form-invalid-feedback :state="errors.length > 0 ? false:null">
                          {{ errors[0] }}
                        </b-form-invalid-feedback>
                      </b-form-group>
                    </validation-provider>
                  </b-col>
                </b-row>
              </validation-observer>
            </tab-content>
            <tab-content :title="$t('visa.step2.title')" :before-change="validationFormBasicInfo">
              <validation-observer ref="basicInfoRules" tag="form">
                <b-row>
                  <b-col cols="12" class="mb-2">
                    <h5 class="mb-0">
                      {{$t('visa.step2.title')}}
                    </h5>
                    <small class="text-muted">{{$t('visa.step2.desc')}}</small>
                  </b-col>
                  <b-col md="6">
                    <validation-provider #default="{ errors }" name="VisaType" rules="required">
                      <b-form-group :label="$t('visa.step2.vt')" label-for="VisaType" class="is_required"
                        :state="errors.length > 0 ? false:null">
                        <v-select id="VisaType" v-model="VisaType" :options="visasData" :dir="'isRTL'"
                          :selectable="option => ! option.value.includes('select_value')"
                          v-on:input="setBranch(VisaType)" label="text" />
                        <b-form-invalid-feedback :state="errors.length > 0 ? false:null">
                          {{ errors[0] }}
                        </b-form-invalid-feedback>
                      </b-form-group>
                    </validation-provider>
                  </b-col>
                  <b-col md="6">
                    <validation-provider #default="{ errors }" name="biometricsLocation" rules="required">
                      <b-form-group :label="$t('visa.step2.bl')" label-for="biometricsLocation"
                        :state="errors.length > 0 ? false:null" class="is_required">
                        <v-select id="VisaType" v-model="biometricsLocation" :options="translated(branchesData)"
                          :items="branchesData" :dir="'isRTL'"
                          :selectable="option => ! option.text.includes('select_value')" label="text" />
                        <b-form-invalid-feedback :state="errors.length > 0 ? false:null">
                          {{ errors[0] }}
                        </b-form-invalid-feedback>
                      </b-form-group>
                    </validation-provider>
                  </b-col>
                  <b-col md="6">
                    <validation-provider #default="{ errors }" name="travel-date" rules="required">
                      <b-form-group :state="errors.length > 0 ? false:null">
                        <label for="travel-date">
                          {{$t('visa.step2.td')}} <span class="required_field"></span>
                          <small>({{$t('visa.step2.travelNote')}})</small>
                        </label>
                        <flat-pickr v-model="travelDate" name="travel-date" id="travel-date" :dir="'isRTL'"
                          class="form-control"
                          :config="{allowInput: true, minDate:new Date().fp_incr(1), altInput: true, altFormat: 'Y-m-d', dateFormat: 'Y-m-d',}"
                          required />
                        <b-form-invalid-feedback :state="errors.length > 0 ? false:null">
                          {{ errors[0] }}
                        </b-form-invalid-feedback>
                      </b-form-group>
                    </validation-provider>
                  </b-col>
                </b-row>
              </validation-observer>
            </tab-content>
            <tab-content :title="$t('visa.step3.h')" class="required_docs">
              <b-row>
                <b-col cols="12" class="mb-2">
                  <h5 class="mb-0">{{$t('visa.step3.title')}}
                  </h5>
                  <small class="text-muted">{{$t('visa.step3.desc')}}</small>
                </b-col>
                <b-col md="12">
                  <b-card :title="$t('visa.step3.h')">
                    <app-collapse>
                      <app-collapse-item :title="$t('visa.step3.pass')">
                        {{$t('visa.step3.passVal')}}
                      </app-collapse-item>
                      <app-collapse-item :title="$t('visa.step3.photo')">
                        <ul class="list-style-icons">
                          <li>
                            <chevron-right-icon size="1.5x" class="rotate"></chevron-right-icon>
                            {{$t('visa.step3.imagesList.1')}}
                          </li>
                          <!-- <li>
                            <chevron-right-icon size="1.5x" class="rotate"></chevron-right-icon>
                            {{$t('visa.step3.imagesList.2')}}
                          </li> -->
                          <!-- <li>
                            {{$t('visa.step3.imagesList.h1')}}
                            <ul class="list-style-icons">
                              <li>
                                <chevron-right-icon size="1.5x" class="rotate"></chevron-right-icon>
                                {{$t('visa.step3.imagesList.3')}}
                              </li>
                              <li>
                                <chevron-right-icon size="1.5x" class="rotate"></chevron-right-icon>
                                {{$t('visa.step3.imagesList.4')}}
                              </li>
                              <li>
                                <chevron-right-icon size="1.5x" class="rotate"></chevron-right-icon>
                                {{$t('visa.step3.imagesList.5')}}
                              </li>
                            </ul>
                          </li> -->
                        </ul>
                      </app-collapse-item>
                      <!-- <app-collapse-item :title="$t('visa.step3.job')">
                        <ul class="list-style-icons">
                          <li>
                            <chevron-right-icon size="1.5x" class="rotate"></chevron-right-icon>
                            {{$t('visa.step3.jobList.1')}}<br />{{$t('visa.step3.jobList.2')}}
                          </li>
                          <li>
                            <chevron-right-icon size="1.5x" class="rotate"></chevron-right-icon>
                            {{$t('visa.step3.jobList.3')}}<br />{{$t('visa.step3.jobList.4')}}
                          </li>
                        </ul>
                      </app-collapse-item> -->
                      <app-collapse-item :title="$t('visa.step3.salary')">
                        <small class="text-muted">{{$t('visa.step3.salaryText')}}</small>
                      </app-collapse-item>
                      <app-collapse-item :title="$t('visa.step3.bank')">
                        <!-- {{$t('visa.step3.bankVal')}} -->
                      </app-collapse-item>
                      <app-collapse-item :title="$t('visa.step3.insur')">
                        {{$t('visa.step3.insurances')}}
                      </app-collapse-item>
                      <!-- <app-collapse-item :title="$t('visa.step3.i20')">
                        {{$t('visa.step3.i20Val')}}
                      </app-collapse-item> -->
                    </app-collapse>
                    <!-- <template #code>
                      {{ codeCollapseDefault }}
                    </template> -->
                  </b-card>
                  <!--Requires Interview in Embassady -->
                  <b-card :title="$t('visa.step3.aslzawejsafar')">
                    <ul class="list-style-icons">
                      <li>
                        <chevron-right-icon size="1.5x" class="rotate"></chevron-right-icon>
                        {{$t('visa.step3.p0_aslzawejsafar')}}
                      </li>
                      <li>
                        <chevron-right-icon size="1.5x" class="rotate"></chevron-right-icon>
                        {{$t('visa.step3.p1_aslzawejsafar')}}
                      </li>
                      <li>
                        <chevron-right-icon size="1.5x" class="rotate"></chevron-right-icon>
                        {{$t('visa.step3.p2_aslzawejsafar')}}
                      </li>
                      <li>
                        <chevron-right-icon size="1.5x" class="rotate"></chevron-right-icon>
                        {{$t('visa.step3.p3_aslzawejsafar')}}
                      </li>
                      <li>
                        <chevron-right-icon size="1.5x" class="rotate"></chevron-right-icon>
                        {{$t('visa.step3.p4_aslzawejsafar')}}
                      </li>
                      <li>
                        <chevron-right-icon size="1.5x" class="rotate"></chevron-right-icon>
                        {{$t('visa.step3.p5_aslzawejsafar')}}
                      </li>
                    </ul>
                  </b-card>
                  <!--Expected visa Issurance Period -->
                  <b-card :title="$t('visa.step3.insurance.title')">
                    <ul class="list-style-icons">
                      <li>
                        <chevron-right-icon size="1.5x" class="rotate"></chevron-right-icon>
                        {{$t('visa.step3.insurance.list.1')}}
                      </li>
                      <!-- <li>
                        <chevron-right-icon size="1.5x" class="rotate"></chevron-right-icon>
                        {{$t('visa.step3.insurance.list.2')}}
                      </li> -->
                    </ul>
                  </b-card>
                  <!--* Translation -->
                  <b-card :title="$t('visa.step3.trans')">
                    <p>
                      {{$t('visa.step3.transVal')}}
                    </p>
                  </b-card>
                </b-col>
              </b-row>
            </tab-content>
            <!-- traveller's details tab -->
            <tab-content :title="$t('visa.step4.title')" :before-change="validationFormTravallersInfo">
              <validation-observer ref="travellersInfoRules" tag="form">
                <!-- Adults -->
                <b-card class="text-end buttons_wrapper_card">
                  <b-button size="md" variant="primary" class="btn-tour-skip" @click="addAdultTraveller">
                    <user-plus-icon size="1.5x" class="custom-class"></user-plus-icon>
                    {{$t('visa.step4.adultT')}}
                  </b-button>
                  <b-button size="md" variant="outline-primary" class="btn-tour-skip mx-2" @click="addChildTraveller">
                    <user-plus-icon size="1.5x" class="custom-class"></user-plus-icon>
                    {{$t('visa.step4.childT')}}
                  </b-button>
                </b-card>
                <b-tabs pills>
                  <b-tab
                    :title="traveller.is_child==0?$t('visa.step4.adult')+' '+(traveller.count):$t('visa.step4.child')+' '+(traveller.count)"
                    :active="index==0" v-for="(traveller,index) in travellers" :key="index">
                    <b-card-text>
                      <b-row>
                        <b-col cols="12">
                          <b-card>
                            <b-row>
                              <b-col cols="12" class="mb-2 d-flex align-items-center justify-content-between">
                                <h5 class="mb-0">
                                  {{$t('visa.step4.pinfo.title')}}
                                </h5>
                                <b-button size="md" variant="danger" class="btn-tour-skip"
                                  v-if="index>0 && traveller.is_child==0" @click="removeAdultTraveller(index)">
                                  <user-minus-icon size="1.5x" class="custom-class"></user-minus-icon>
                                  {{$t('visa.step4.adultT')}}
                                </b-button>
                                <b-button size="md" variant="danger" class="btn-tour-skip"
                                  @click="removeChildTraveller(index)" v-if="index>0 && traveller.is_child==1">
                                  <user-minus-icon size="1.5x" class="custom-class"></user-minus-icon>
                                  {{$t('visa.step4.childT')}}
                                </b-button>
                              </b-col>
                              <b-col md="6" class="mb-2">
                                <validation-provider #default="{ errors }" name="first_name" rules="required">
                                  <b-form-group :label="$t('form.fname')" label-for="first-name" class="is_required">
                                    <b-form-input v-model="traveller.first_name" :placeholder="$t('form.fname')"
                                      :state="errors.length > 0 ? false:null" required />
                                    <b-form-invalid-feedback :state="errors.length > 0 ? false:null">
                                      {{ errors[0] }}
                                    </b-form-invalid-feedback>
                                  </b-form-group>
                                </validation-provider>
                              </b-col>
                              <b-col md="6" class="mb-2">
                                <b-form-group :label="$t('form.lname')" label-for="last-name" class="is_required">
                                  <validation-provider #default="{ errors }" name="last-name" rules="required">
                                    <b-form-input v-model="traveller.last_name" :state="errors.length > 0 ? false:null"
                                      :placeholder="$t('form.lname')" />
                                    <small class="text-danger">{{ errors[0] }}</small>
                                  </validation-provider>
                                </b-form-group>
                              </b-col>
                              <b-col v-if="index==0" md="6" class="mb-2">
                                <b-form-group :label="$t('form.mailId')" label-for="email" class="is_required">
                                  <validation-provider #default="{ errors }" name="email" rules="required">
                                    <b-form-input v-model="traveller.email" type="email"
                                      :state="errors.length > 0 ? false:null" placeholder="example@email.com" />
                                    <small class="text-danger">{{ errors[0] }}</small>
                                  </validation-provider>
                                </b-form-group>
                              </b-col>
                              <b-col md="6" class="mb-2">
                                <validation-provider #default="{ errors }" name="status" rules="required">
                                  <b-form-group :label="$t('form.mst')" label-for="status"
                                    :state="errors.length > 0 ? false:null">
                                    <v-select v-model="traveller.maritalStatus" :dir="'isRTL'"
                                      :options="translated2(maritalStatuses)"
                                      :selectable="option => ! option.value.includes('select_value')" label="text" />
                                    <b-form-invalid-feedback :state="errors.length > 0 ? false:null">
                                      {{ errors[0] }}
                                    </b-form-invalid-feedback>
                                  </b-form-group>
                                </validation-provider>
                              </b-col>
                              <b-col md="6" class="mb-2">
                                <b-form-group :label="$t('visa.step4.pinfo.pass')" label-for="passport"
                                  class="is_required">
                                  <validation-provider #default="{ errors }" name="passport-id" rules="required">
                                    <b-form-file v-model="traveller.passport" @change="onFileChange(index,$event)"
                                      :placeholder="$t('visa.step4.pinfo.hold1')"
                                      drop-placeholder="Drop file here..." />
                                    <small class="text-danger">{{ errors[0] }}</small>
                                  </validation-provider>
                                </b-form-group>
                              </b-col>
                              <b-col md="6" class="mb-2">
                                <b-form-group :label="$t('visa.step4.pinfo.phone')" label-for="phone"
                                  class="is_required">
                                  <validation-provider #default="{ errors }" name="phone" rules="required">
                                    <b-form-input v-model="traveller.tel" placeholder="05 XXX XXXX" type="number"
                                      :state="errors.length > 0 ? false:null" />
                                    <small class="text-danger">{{ errors[0] }}</small>
                                  </validation-provider>
                                </b-form-group>
                              </b-col>
                              <b-col md="6" class="mb-2">
                                <b-form-group :label="$t('visa.step4.pinfo.famId')" label-for="family-id">
                                  <b-form-file v-if="!traveller.later_family_id" v-model="traveller.family_id"
                                    @change="onFileChangeFamilyId(index,$event)"
                                    :placeholder="$t('visa.step4.pinfo.hold1')" drop-placeholder="Drop file here..."
                                    :disabled="traveller.later_family_id == 1" />
                                </b-form-group>
                                <div class="d-flex">
                                  <b-form-checkbox checked="false" v-model="traveller.later_family_id" switch inline
                                    class="mx-1">
                                    &nbsp;{{ $t('visa.step4.questions.later_img')}}
                                  </b-form-checkbox>
                                </div>
                              </b-col>
                              <b-col md="6" class="mb-2">
                                <b-form-group :label="$t('visa.step4.pinfo.photo')" label-for="photo">
                                  <b-form-file v-if="!traveller.later_photo" v-model="traveller.photo"
                                    @change="onFileChangePhoto(index,$event)"
                                    :placeholder="$t('visa.step4.pinfo.hold1')" drop-placeholder="Drop file here..."
                                    :disabled="traveller.later_photo == 1" />
                                </b-form-group>
                                <div class="d-flex">
                                  <b-form-checkbox checked="false" v-model="traveller.later_photo" switch inline
                                    class="mx-1">
                                    &nbsp;{{ $t('visa.step4.questions.later_img')}}
                                  </b-form-checkbox>
                                </div>
                              </b-col>
                              <!--salaire-->
                              <b-col md="6" v-if="traveller.is_child==0" class="mb-2">
                                <b-form-group style="margin-top:15px" :label="$t('visa.step4.pinfo.salaire')"
                                  label-for="salaire">
                                  <b-form-file v-if="!traveller.later_salaire" v-model="traveller.salaire"
                                    @change="onFileChangeSalaire(index,$event)"
                                    :placeholder="$t('visa.step4.pinfo.hold1')" drop-placeholder="Drop file here..."
                                    :disabled="traveller.later_salaire == 1" />
                                </b-form-group>
                                <div class="d-flex">
                                  <b-form-checkbox checked="false" v-model="traveller.later_salaire" switch inline
                                    class="mx-1">
                                    &nbsp;{{ $t('visa.step4.questions.later_img')}}
                                  </b-form-checkbox>
                                </div>
                              </b-col>
                              <!--assurance-->
                              <b-col md="6" v-if="traveller.is_child==0" class="mb-2">
                                <b-form-group style="margin-top:15px" :label="$t('visa.step4.pinfo.assurance')"
                                  label-for="assurance">
                                  <b-form-file v-if="!traveller.later_assurance" v-model="traveller.assurance"
                                    @change="onFileChangeAssurance(index,$event)"
                                    :placeholder="$t('visa.step4.pinfo.hold1')" drop-placeholder="Drop file here..."
                                    :disabled="traveller.later_assurance == 1" />
                                </b-form-group>
                                <div class="d-flex">
                                  <b-form-checkbox checked="false" v-model="traveller.later_assurance" switch inline
                                    class="mx-1">
                                    &nbsp;{{ $t('visa.step4.questions.later_img')}}
                                  </b-form-checkbox>
                                </div>
                              </b-col>
                              <b-col md="6">
                                <b-form-group :label="$t('visa.step4.pinfo.bankStatment')" label-for="bankStatment-id">
                                  <b-form-file v-if="!traveller.later_bankStatment" v-model="traveller.bankStatment"
                                    @change="onFileChangeBankStmt(index,$event)"
                                    :placeholder="$t('visa.step4.pinfo.hold1')" drop-placeholder="Drop file here..."
                                    :disabled="traveller.later_bankStatment == 1" />
                                </b-form-group>
                                <div class="d-flex">
                                  <b-form-checkbox checked="false" v-model="traveller.later_bankStatment" switch inline
                                    class="mx-1">
                                    &nbsp;{{ $t('visa.step4.questions.later_img')}}
                                  </b-form-checkbox>
                                </div>
                              </b-col>
                            </b-row>
                          </b-card>
                        </b-col>
                        <b-col cols="12">
                          <b-card :title="
                                  $t('visa.step4.questions.1') +' '+
                                  selectedContry.text +' '+
                                  $t('visa.step4.questions.2')
                                ">
                            <b-row class="d-flex align-items-center">
                              <b-col md="3" class="d-flex">
                                {{ $t('visa.step4.questions.no')}}
                                <b-form-checkbox checked="false" v-model="traveller.has_old_visa" switch inline
                                  class="mx-1">
                                  &nbsp;{{ $t('visa.step4.questions.yes')}}
                                </b-form-checkbox>
                              </b-col>
                              <b-col md="4" v-if="traveller.has_old_visa">
                                <validation-provider #default="{ errors }" name="prev-visa" rules="required">
                                  <b-form-file v-model="traveller.prev_visa"
                                    @change="onFileChangePrevVisa(index,$event)" class="my-1"
                                    :placeholder="$t('visa.step4.pinfo.pvdata')" drop-placeholder="Previous Visa"
                                    :disabled="traveller.selected_old_visa == 1" />
                                  <small class="text-danger">{{ errors[0] }}</small>
                                </validation-provider>
                              </b-col>
                              <b-col md="5" v-if="traveller.has_old_visa">
                                <b-form-checkbox v-model="traveller.selected_old_visa" checked="false" switch inline plain>
                                  {{$t('visa.step4.questions.12')}}
                                </b-form-checkbox>
                              </b-col>
                            </b-row>
                          </b-card>
                        </b-col>
                        <b-col cols="12">
                          <b-card :title="
                                  $t('visa.step4.questions.3') +' '+
                                  selectedContry.text +' '+
                                  $t('visa.step4.questions.4')
                                ">
                            <b-row>
                              <b-col md="12" class="d-flex">
                                {{ $t('visa.step4.questions.no')}}&nbsp;
                                <b-form-checkbox checked="false" v-model="traveller.has_travelled" switch inline>
                                  &nbsp;{{ $t('visa.step4.questions.yes')}}
                                </b-form-checkbox>
                              </b-col>
                              <b-col md="6" v-if="traveller.has_travelled" class="py-2">
                                <validation-provider #default="{ errors }" name="lastStayLength" rules="required">
                                  <b-form-group :label="$t('visa.step4.questions.10')" label-for="lastStayLength"
                                    :state="errors.length > 0 ? false:null">
                                    <v-select v-model="traveller.lastStayLength" :dir="'isRTL'"
                                      :options="lastStayLengths"
                                      :selectable="option => ! option.value.includes('select_value')" label="text" />
                                    <b-form-invalid-feedback :state="errors.length > 0 ? false:null">
                                      {{ errors[0] }}
                                    </b-form-invalid-feedback>
                                  </b-form-group>
                                </validation-provider>
                              </b-col>
                              <b-col md="6" v-if="traveller.has_travelled" class="py-2">
                                <!--date of last arrival-->
                                <b-form-group>
                                  <label for="textarea-default">{{ $t('visa.step4.questions.11')}}</label>
                                  <flat-pickr v-model="traveller.last_arrival" class="form-control"
                                    :config="{ maxDate:new Date(), altInput: true,altFormat: 'Y-m-d', dateFormat: 'y-m-d'}" />
                                </b-form-group>
                              </b-col>
                              <b-col md="12" v-if="traveller.has_travelled">
                                <h6>{{ $t('visa.step4.questions.9')}} {{selectedContry.text}}
                                  {{ $t('visa.step4.questions.91')}}</h6>
                                <div class="d-flex">
                                  {{ $t('visa.step4.questions.no')}}&nbsp;
                                  <b-form-checkbox checked="false" v-model="traveller.has_drivingLicence" switch inline>
                                    &nbsp; {{ $t('visa.step4.questions.yes')}}
                                  </b-form-checkbox>
                                </div>
                              </b-col>
                            </b-row>
                          </b-card>
                        </b-col>
                        <b-col cols="12">
                          <b-card :title="
                                  $t('visa.step4.questions.5') +' '+
                                  selectedContry.text +' '+
                                  $t('visa.step4.questions.6')
                                ">
                            <b-row>
                              <b-col md="12" class="mb-2 d-flex">
                                {{ $t('visa.step4.questions.no')}}&nbsp;
                                <b-form-checkbox checked="false" v-model="traveller.visa_cancelled" switch inline>
                                  &nbsp;{{ $t('visa.step4.questions.yes')}}
                                </b-form-checkbox>
                              </b-col>
                              <b-col md="12" v-if="traveller.visa_cancelled">
                                <!--reasons-->
                                <b-form-textarea v-model="traveller.reasons" :placeholder="$t('placeholders.reasons')"
                                  rows="3" />
                              </b-col>
                            </b-row>
                          </b-card>
                        </b-col>
                        <b-col cols="12">
                          <b-card :title="
                                  $t('visa.step4.questions.7') +' '+
                                  selectedContry.text +' '+
                                  $t('visa.step4.questions.8')
                                ">
                            <b-row>
                              <b-col md="12" class="mb-2 d-flex">
                                {{ $t('visa.step4.questions.no')}}&nbsp;
                                <b-form-checkbox checked="false" v-model="traveller.visa_relatives" switch inline>
                                  &nbsp; {{ $t('visa.step4.questions.yes')}}
                                </b-form-checkbox>
                              </b-col>
                              <b-col md="12" v-if="traveller.visa_relatives">
                                <!--Relative Type-->
                                <validation-provider #default="{ errors }" name="lastStayLength" rules="required">
                                  <b-form-group :label="$t('visa.step4.questions.19')" label-for="lastStayLength"
                                    :state="errors.length > 0 ? false:null">
                                    <v-select v-model="traveller.visaRelativesTypes" :dir="'isRTL'"
                                      :options="visa_relatives_types"
                                      :selectable="option => ! option.value.includes('select_value')" label="text" />
                                    <b-form-invalid-feedback :state="errors.length > 0 ? false:null">
                                      {{ errors[0] }}
                                    </b-form-invalid-feedback>
                                  </b-form-group>
                                </validation-provider>
                              </b-col>
                            </b-row>
                          </b-card>
                        </b-col>
                      </b-row>
                    </b-card-text>
                  </b-tab>
                </b-tabs>
              </validation-observer>
            </tab-content>
            <!-- payment link -->
            <tab-content :title="$t('visa.step5.title')">
              <b-row>
                <b-col cols="12" class="mb-2">
                  <h5 class="mb-0">{{$t('visa.step5.title')}}</h5>
                  <small class="text-muted">{{$t('visa.step5.desc')}}</small>
                </b-col>
                <b-col cols="12" class="mt-2">
                  <b-form-group>
                    <b-form-radio name="payment-method" v-model="brand" value="VISA" @click="setPaymentGateway('VISA')">
                      {{$t("payment.visa")}}
                    </b-form-radio>
                    <b-form-radio name="payment-method" class="mt-1" v-model="brand" value="CASH_ON_DELIVERY"
                      @click="setPaymentGateway('CASH_ON_DELIVERY')">
                      {{$t("payment.cash")}}
                    </b-form-radio>
                  </b-form-group>
                  <hr class="my-2">
                  <div class="m-4 flex mt-10 min-h-payment-form relative" dir="ltr" id="hyperpay-form"></div>
                </b-col>
              </b-row>
            </tab-content>
          </form-wizard>
        </div>
      </div>
    </section>
    <Newsletter />
  </main>
  <!-- End #main -->
</template>

<script>
  import http from "../services/api";
  import Newsletter from "@/components/partials/Newsletter";
  import {
    FormWizard,
    TabContent
  } from "vue-form-wizard";
  import "vue-form-wizard/dist/vue-form-wizard.min.css";
  import AppCollapse from "../components/app-collapse/AppCollapse.vue";
  import AppCollapseItem from "../components/app-collapse/AppCollapseItem.vue";
  import {
    ChevronRightIcon
  } from 'vue-feather-icons';
  import {
    UserMinusIcon,
    UserPlusIcon
  } from 'vue-feather-icons'
  import {
    BFormRadio,
    BButton,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BCardText,
    BFormFile,
    BFormCheckbox,
    BFormTextarea,
    BTab,
    BTabs
  } from 'bootstrap-vue';
  import {
    ValidationProvider,
    ValidationObserver
  } from 'vee-validate';
  import flatPickr from 'vue-flatpickr-component';
  import 'flatpickr/dist/flatpickr.css';
  import VueIziToast from 'vue-izitoast';
  import 'izitoast/dist/css/iziToast.css';

  export default {
    name: "VisaFilling",
    metaInfo: {
      title: "Visa Filling",
    },
    components: {

      BButton,
      BFormRadio,
      BRow,
      BCol,
      BFormGroup,
      BFormInput,
      BFormInvalidFeedback,
      BCardText,
      BFormFile,
      BFormCheckbox,
      BFormTextarea,
      BTab,
      BTabs,
      UserMinusIcon,
      UserPlusIcon,
      Newsletter,
      FormWizard,
      TabContent,
      AppCollapse,
      AppCollapseItem,
      ChevronRightIcon,
      ValidationProvider,
      ValidationObserver,
      flatPickr,
      // eslint-disable-next-line vue/no-unused-components
      VueIziToast,
    },
    data() {
      return {
        biometricsLocation: "",
        branchesData: [],
        visasData: [],
        listpassport: [],
        listphoto: [],
        listfamilyIds: [],
        listOldVisas: [],
        salaires: [],
        assurances: [],
        bankStatments: [],
        bankStatment: [],
        isRtl: 'ltr',
        isVisible: true,
        dateValue: "",
        selectedCountry: null,
        option: [],
        paysData: null,
        countries: [],
        travellerRelationship: '',
        //payment
        items: [],
        showCheckoutForm: false,
        redirect_url: null,
        paymentType: null,
        brands: [],
        brand: 'CASH_ON_DELIVERY',
        hyPayPayFormLoading: true,
        //payment
        total_travellers: 1,
        total_numChildTravellers: 0,
        travellers: [{
          visa_cancelled: 0,
          has_old_visa: 0,
          has_travelled: 0,
          has_drivingLicence: 0,
          selected_old_visa: 0,
          prev_visa: null,
          photo: null,
          salaire: null,
          assurance: null,
          passport: null,
          family_id: null,
          lastStayLength: '',
          visaRelativesTypes: '',
          maritalStatus: '',
          visa_relatives: 0,
          email: '',
          first_name: null,
          last_name: null,
          reasons: '',
          last_arrival: '',
          is_child: 0,
          count: 1,
          later_photo: 0,
          later_salaire: 0,
          later_family_id: 0,
          later_assurance: 0,
          bankStatment: null,
          later_bankStatment: 0
        }],
        visa_relatives_types: [{
            value: "1",
            text: "Father"
          },
          {
            value: "2",
            text: "Mother"
          },
          {
            value: "3",
            text: "Brother"
          },
          {
            value: "4",
            text: "Sister"
          },
          {
            value: "5",
            text: "Son"
          },
          {
            value: "6",
            text: "Daughter"
          },
          {
            value: "7",
            text: "Husband/Wife"
          },
        ],

        lastStayLengths: [{
            value: "1",
            text: "1 Day"
          },
          {
            value: "2",
            text: "2 Days"
          },
          {
            value: "3",
            text: "3 Days"
          },
          {
            value: "4",
            text: "4 Days"
          },
          {
            value: "5",
            text: "5 Days"
          },
          {
            value: "6",
            text: "6 Days"
          },
          {
            value: "7",
            text: "7 Days"
          },
          {
            value: "8",
            text: "8 Days"
          },
          {
            value: "9",
            text: "9 Days"
          },
          {
            value: "10",
            text: "10 Days"
          },
          {
            value: "11",
            text: "11 Day"
          },
          {
            value: "12",
            text: "12 Days"
          },
          {
            value: "13",
            text: "13 Days"
          },
          {
            value: "14",
            text: "14 Days"
          },
          {
            value: "15",
            text: "15 Days"
          },
          {
            value: "16",
            text: "16 Days"
          },
          {
            value: "17",
            text: "17 Days"
          },
          {
            value: "18",
            text: "18 Days"
          },
          {
            value: "19",
            text: "19 Days"
          },
          {
            value: "20",
            text: "20 Days"
          },
          {
            value: "21",
            text: "21 Days"
          },
          {
            value: "22",
            text: "22 Day"
          },
          {
            value: "23",
            text: "23 Days"
          },
          {
            value: "24",
            text: "24 Days"
          },
          {
            value: "25",
            text: "25 Days"
          },
          {
            value: "26",
            text: "26 Days"
          },
          {
            value: "27",
            text: "27 Days"
          },
          {
            value: "28",
            text: "28 Days"
          },
          {
            value: "29",
            text: "29 Days"
          },
          {
            value: "30",
            text: "30 Days (1 month)"
          },
          {
            value: "31",
            text: "2 Months"
          },
          {
            value: "32",
            text: "3 Months"
          },
          {
            value: "33",
            text: "4 Months"
          },
          {
            value: "34",
            text: "5 Months"
          },
          {
            value: "35",
            text: "6 Months"
          },
          {
            value: "36",
            text: "7 Months"
          },
          {
            value: "37",
            text: "8 Months"
          },
          {
            value: "38",
            text: "9 Months"
          },
          {
            value: "39",
            text: "10 Months"
          },
          {
            value: "40",
            text: "11 Months"
          },
          {
            value: "41",
            text: "12 Months"
          },
          {
            value: "42",
            text: "2 Years"
          },
          {
            value: "43",
            text: "3 Years"
          },
          {
            value: "44",
            text: "4 Years"
          },
          {
            value: "45",
            text: "5 Years"
          },
        ],

        maritalStatuses: [{
            value: "1",
            text: {
              "en": "Single",
              "ar": "أعزب"
            }
          },
          {
            value: "2",
            text: {
              "en": "Married",
              "ar": "متزوج"
            }
          }
        ],
        numTravellers: {
          value: "-1",
          text: "Nothing Selected"
        },
        numChildTravellers: {
          value: "-1",
          text: "0"
        },
        travellersRelationships: [{
            value: "1",
            text: "Family"
          },
          {
            value: "2",
            text: "Friends"
          },
          {
            value: "3",
            text: "Other"
          },
        ],
        travelDate: null,
        selectedContry: "",
        selectedLanguage: "",
        VisaType: '',

      };
    },
   async created() {
       await http.get(`/get/pays`).then((response) => {
        this.paysData = response.data.data;
        this.updateCountriesSelect(response.data.data)
      });
      this.isRtl = this.getLocale();
    },

    methods: {

      onFileChange(index, e) {
        const p_exper_pro_upload = e.target.files[0];
        const dataUp = [];
        dataUp['index'] = index;
        dataUp['files'] = p_exper_pro_upload;
        this.listpassport.push(dataUp);
        console.log(this.listpassport, 'array passport');
      },
      onFileChangePhoto(index, e) {
        const p_exper_pro_upload = e.target.files[0];
        const dataUp = [];
        dataUp['index'] = index;
        dataUp['files'] = p_exper_pro_upload;
        this.listphoto.push(dataUp);
        console.log(this.listphoto, 'array photos');
      },
      onFileChangeFamilyId(index, e) {
        const p_exper_pro_upload = e.target.files[0];
        const dataUp = [];
        dataUp['index'] = index;
        dataUp['files'] = p_exper_pro_upload;
        this.listfamilyIds.push(dataUp);
      },
      onFileChangePrevVisa(index, e) {
        const p_exper_pro_upload = e.target.files[0];
        const dataUp = [];
        dataUp['index'] = index;
        dataUp['files'] = p_exper_pro_upload;
        this.listOldVisas.push(dataUp);
      },
      onFileChangeSalaire(index, e) {
        const p_exper_pro_upload = e.target.files[0];
        const dataUp = [];
        dataUp['index'] = index;
        dataUp['files'] = p_exper_pro_upload;
        this.salaires.push(dataUp);
      },
      onFileChangeAssurance(index, e) {
        const p_exper_pro_upload = e.target.files[0];
        const dataUp = [];
        dataUp['index'] = index;
        dataUp['files'] = p_exper_pro_upload;
        this.assurances.push(dataUp);
      },
      onFileChangeBankStmt(index, e) {
        const p_exper_pro_upload = e.target.files[0];
        const dataUp = [];
        dataUp['index'] = index;
        dataUp['files'] = p_exper_pro_upload;
        this.bankStatments.push(dataUp);
      },
      addAdultTraveller() {
        this.total_travellers++;
        this.travellers.push({
          visa_cancelled: 0,
          has_old_visa: 0,
          has_travelled: 0,
          has_drivingLicence: 0,
          selected_old_visa: 0,
          prev_visa: null,
          photo: null,
          salaire: null,
          passport: null,
          assurance: null,
          family_id: null,
          lastStayLength: '',
          visaRelativesTypes: '',
          maritalStatus: '',
          visa_relatives: 0,
          email: '',
          first_name: '',
          last_name: '',
          reasons: '',
          last_arrival: '',
          is_child: 0,
          count: this.total_travellers,
          later_photo: 0,
          later_salaire: 0,
          later_family_id: 0,
          later_assurance: 0,
          bankStatment: null,
          later_bankStatment: 0
        })

      },
      addChildTraveller() {
        this.total_numChildTravellers++;
        this.travellers.push({
          visa_cancelled: 0,
          has_old_visa: 0,
          has_travelled: 0,
          has_drivingLicence: 0,
          selected_old_visa: 0,
          prev_visa: null,
          photo: null,
          passport: null,
          family_id: null,
          lastStayLength: '',
          visaRelativesTypes: '',
          maritalStatus: '',
          visa_relatives: 0,
          email: '',
          first_name: '',
          last_name: '',
          reasons: '',
          last_arrival: '',
          is_child: 1,
          count: this.total_numChildTravellers,
          later_photo: 0,
          bankStatment: null,
          later_bankStatment: 0
        })

      },
      removeAdultTraveller(index) {
        this.total_travellers--;
        this.travellers.splice(index, 1);
      },
      removeChildTraveller(index) {
        this.total_numChildTravellers--;
        this.travellers.splice(index, 1);

      },
      getLocale() {
        const locale = this.$route.params.locale == "ar" ? "rtl" : "ltr";
        return locale;
      },
      validateAsyncCountry() {
        return new Promise((resolve, reject) => {
          this.$refs.countryRules.validate().then(success => {
            if (success) {
              resolve(true)
            } else {
              reject()
            }
          })
        })
      },
      validationFormBasicInfo() {
        return new Promise((resolve, reject) => {
          this.$refs.basicInfoRules.validate().then(success => {
            if (success) {
              resolve(true)
            } else {
              reject()
            }
          })
        })
      },
      //travellersInfoRules
      validationFormTravallersInfo() {
        return new Promise((resolve, reject) => {
          this.$refs.travellersInfoRules.validate().then(success => {
            if (success) {
              resolve(true)
            } else {
              reject()
            }
          })
        })
      },
      updateCountriesSelect(data) {
        data.forEach(elem => {
          this.countries.push({
            "value": elem.id.toString(),
            "text": elem.name
          })
        })
      },
     async formSubmitted() {
        const formData = new FormData();
        formData.append('data[]', JSON.stringify(this.$data.travellers));
        for (var i = 0; i < this.listpassport.length; i++) {
          const file = this.listpassport[i].files;
          const index = this.listpassport[i].index;
          formData.append('passport[' + index + ']', file);
        }
        for (var j = 0; j < this.listphoto.length; j++) {
          const file = this.listphoto[j].files;
          const index = this.listphoto[j].index;
          formData.append('photo[' + index + ']', file);
        }
        //listfamilyIds
        for (var k = 0; k < this.listfamilyIds.length; k++) {
          const file = this.listfamilyIds[k].files;
          const index = this.listfamilyIds[k].index;
          formData.append('familyId[' + index + ']', file);
        }
        //listOldVisas
        for (var s = 0; s < this.listOldVisas.length; s++) {
          const file = this.listOldVisas[s].files;
          const index = this.listOldVisas[s].index;
          formData.append('oldVisa[' + index + ']', file);
        }
        //list of salaire
        for (var sal = 0; sal < this.salaires.length; sal++) {
          const file = this.salaires[sal].files;
          const index = this.salaires[sal].index;
          formData.append('salaire[' + index + ']', file);
        }
        //list of assurance
        for (var assr = 0; assr < this.assurances.length; assr++) {
          const file = this.assurances[assr].files;
          const index = this.assurances[assr].index;
          formData.append('assurance[' + index + ']', file);
        }

        //list of bank Statments
        for (var bks = 0; bks < this.bankStatments.length; bks++) {
          const file = this.bankStatments[bks].files;
          const index = this.bankStatments[bks].index;
          formData.append('bankStatment[' + index + ']', file);
        }
        formData.append('VisaType', this.VisaType.value);
        formData.append('country', this.selectedContry.text);
        formData.append('branch', this.biometricsLocation.text);
        formData.append('date_from', this.travelDate);

        let PayementData = {
          "brand": this.brand,
          "amount": this.biometricsLocation.price,
        }
        formData.append('PayementData', JSON.stringify(PayementData));

        const headers = {
          'Content-Type': 'multipart/form-data'
        };
        if (localStorage.getItem('jwt') == null) {
          this.$router.push('Login')
          }else{
            await http.post('/traveller_store', formData, {
              headers
            }).then((res) => {
              if (res.data.status) {
                this.$destroy();
                this.$toast.success('Payment success');
                this.$router.push(`/`);

              } else {
                this.$toast.error('An error has occured');
              }

            })
          }
      },

      setChildTotalTravellers() {
        this.total_numChildTravellers = parseInt(this.numChildTravellers.value);
      },
      setTotalTravellers() {
        this.total_travellers = parseInt(this.numTravellers.value);
      },
      setBranch(val) {
        // console.log(val, 'set branch')
        this.branchesData = [];
        val.typebranch.forEach(b => {
          this.branchesData.push({
            "id": b.id.toString(),
            "value": b.name,
            "price": b.price,
            "text": b.name
          })
        })
      },
      setCountry() {
        let country = this.selectedContry.value
        const payees = []
        this.paysData.forEach(function (set) {
          if (set.id == country) {
            payees.push(set)
          }
        })

        /* get visa */

        payees.forEach(elem => {
          this.visasData = [];
          elem.visas.forEach(d => {
            d.typevisa.forEach(e => {
              this.visasData.push({
                "id": e.id.toString(),
                "value": e.name,
                "text": e.name,
                'typebranch': e.branches
              })
            })
          })
        })
      },

      translated(data) {
        // const locale = this.$route.params.locale;
        let final = [];
        data.forEach(element => {
          final.push({
            //  value:element.text[locale],
            //  text:element.text[locale]
            value: element.text,
            text: element.text,
            price: element.price
          })
        });
        return final;
      },
      translated2(data) {
        const locale = this.$route.params.locale;
        let final = [];
        data.forEach(element => {
          final.push({
            value: element.text[locale],
            text: element.text[locale]
          })
        });
        return final;
      },
    },
  };
</script>

<style lang="scss" scoped>
  .banner_layer {
    background: rgb(19 22 84 / 84%);
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;
    padding: 200px 0 120px;
  }

  .hotels_section {
    background: url(../../public/assets/img/banners/flights.webp) bottom no-repeat;
    background-size: 100% 390px;
  }

  .vue-form-wizard .wizard-nav-pills>li>a {
    flex-direction: row !important;
  }

  .list-style-circle {
    list-style-type: disc;
  }

  ul {
    list-style-type: none;
  }

  .vue-form-wizard {
    background-color: #fff;
    // box-shadow: 0px 4px 25px 0px rgb(34 41 47 / 10%);
    box-shadow: 0px 0px 2px 0px;
    border-radius: 0.5rem;
    padding: 20px 10px;
    text-align: initial;
    font-size: 14px;
  }

  .vue-form-wizard label {
    color: #5e5873;
    font-size: 0.857rem;
  }

  .form-group {
    margin-bottom: 1rem;
    text-align: initial;
  }

  [dir=ltr] .list-style-icons {
    padding-left: 10px;
    margin-left: 0;
  }

  [dir=rtl] .list-style-icons {
    padding-right: 10px;
    margin-right: 0;
  }

  .required_docs li {
    font-size: 14px;
    font-weight: 400;
    line-height: 1.45;
    color: #6e6b7b;
  }

  // RTL
  html[dir='rtl'] {
    .flatpickr-calendar {

      .flatpickr-prev-month,
      .flatpickr-next-month {
        svg {
          transform: rotate(180deg);
        }
      }
    }
  }
</style>