<template>
    <div class="card">
        <div class="card-header">
            <h5>{{$t("dashboard.actdetails")}}</h5>
            {{form}}
        </div>
        <div class="card-body">
            <!-- <p>{{$t("dashboard.old")}} <a href="page-login-register.html">{{$t("dashboard.betterlogin")}}</a></p> -->
            <form @submit.prevent="handleSubmit">
                <div class="row">
                    <!-- <div class="form-group col-md-6">
                        <label>First Name <span class="required">*</span></label>
                        <input required="" class="form-control square" name="name" type="text">
                    </div> -->
                    <!-- <div class="form-group col-md-6">
                        <label>Name <span class="required">*</span></label>
                        <input required class="form-control square" name="phone">
                    </div> -->
                    <div class="form-group col-md-12">
                        <label>{{$t("account.n")}} <span class="required">*</span></label>
                        <input :class="{ 'is-invalid': isSubmitted && $v.form.name_en.$error }" v-model="form.name_en" class="form-control square" name="dname" type="text">
                            <div v-if="isSubmitted && !$v.form.name_en.required" class="invalid-feedback">{{$t("form.validation.nreq")}}</div>
                    </div>
                    <div class="form-group col-md-12">
                        <label>{{$t("account.mail")}} <span class="required">*</span></label>
                        <input :class="{ 'is-invalid': isSubmitted && $v.form.email.$error }" v-model="form.email" class="form-control square" name="email" type="email">
                        <div v-if="isSubmitted && $v.form.email.$error" class="invalid-feedback">
                            <span v-if="!$v.form.email.required">{{$t("form.validation.emailreq")}}</span>
                            <span v-if="!$v.form.email.email">{{$t("form.validation.emailvalid")}}</span>
                        </div>
                    </div>
                    <div class="form-group col-md-12">
                        <label>{{$t("account.currentpwd")}} <span class="required">*</span></label>
                        <input v-model="form.current_password"
                            :class="{ 'is-invalid': isSubmitted && $v.form.current_password.$error }"  class="form-control square" name="password" type="password">
                            <div v-if="isSubmitted && !$v.form.current_password.required" class="invalid-feedback">{{$t("form.validation.nreq")}}</div>
                    </div>
                    <div class="form-group col-md-12">
                        <label>{{$t("account.newpwd")}} <span class="required">*</span></label>
                        <input v-model="form.password"
                            :class="{ 'is-invalid': isSubmitted && $v.form.password.$error }" class="form-control square" name="npassword" type="password">
                        <div v-if="isSubmitted && $v.form.password.$error" class="invalid-feedback">
                            <span v-if="!$v.form.password.required">{{$t("form.validation.pwdreq")}}</span>
                            <span v-if="!$v.form.password.minLength">{{$t("account.fivelong")}}</span>
                        </div>
                    </div>
                    <div class="form-group col-md-12">
                        <label>{{$t("account.conpwd")}} <span class="required">*</span></label>
                        <input v-model="form.password_confirmation" :class="{ 'is-invalid': isSubmitted && $v.form.password_confirmation.$error }" class="form-control square" name="cpassword" type="password">
                        <div v-if="isSubmitted && $v.form.password_confirmation.$error" class="invalid-feedback">
                            <span v-if="!$v.form.password_confirmation.required">{{$t("form.validation.pwdconfreq")}}</span>
                            <span v-else-if="!$v.form.password_confirmation.sameAsPassword">{{$t("form.validation.pwdmatch")}}</span>
                        </div>
                    </div>
                    <div class="col-md-12">
                        <button type="submit" class="btn btn-fill-out submit">{{$t("btns.save")}}</button>
                    </div>
                    <span v-show="elementVisible" class="hideElement" v-bind:style="style"> {{message}} </span>
                </div>
            </form>
        </div>
    </div>
</template>

<script>
    import {
        required,
        email,
        minLength,
        sameAs
    } from "vuelidate/lib/validators";
    import UserService from '../../services/user.service';
    import EventBus from "../../EventBus";
    import { mapMutations } from 'vuex';
    export default {
        name: "Account",
        components: {
        },
        data() {
            return {
                elementVisible: false,
                style: {
                    color: '',
                },
                message:'',
                form: {
                    name_en: "",
                    email: "",
                    name_ar: "",
                    password: "",
                    current_password: "",
                    password_confirmation: "",
                },
                isSubmitted: false,
            }
        },
        validations: {
            form: {
                email: {
                    required,
                    email
                },
                name_en: {
                    required,
                },
                current_password: {
                    required,
                },
                password: {
                    required,
                    minLength: minLength(5)
                },
                password_confirmation: {
                    required,
                    sameAsPassword: sameAs('password')
                },
                name_ar: {
                    required,
                },
            },
        },

        created() {
            // this.getprofile();
        },
        mounted() {
            if (!this.currentUser) {
                this.$router.push('Login');
            }
            //user profile
            UserService.getUserBoard().then(
                (response) => {
                    console.log(response,'aaa')
                    this.form={
                        name_en :response.data.name,
                        name_ar :response.data.name,
                        email: response.data.email,
                    }
                },
                error => {
                    this.form =
                        (error.response && error.response.data && error.response.data.message) ||
                        error.message ||
                        error.toString();

                    if (error.response && error.response.status === 403) {
                        // console.log(response)
                        EventBus.dispatch("logout");
                    }
                }
            );
        },

        computed: {
            currentUser() {
                // console.log(this.$store.state.auth.user)
                return this.$store.state.auth.user;
            }
        },
        methods: {
            // getprofile(){
            //     http.post('/profile').then(res => {
            //         this.profileData = res.data
            //         console.log(this.profileData);
            //     })
            // }
            ...mapMutations([
                'UpdateSuccess'
            ]),
            handleSubmit() {
                this.isSubmitted = true;
                this.$v.form.$touch();
                if (this.$v.form.$invalid) {
                    return;
                }
                this.Submit(this.form)
            },
            Submit(form) {
                UserService.updateUser(form).then(
                    data => {
                        this.UpdateSuccess(data.data.data);
                        this.style.color = 'green';
                        this.message = data.data.message;
                        setTimeout(() => this.elementVisible = true, 0)
                    },
                    error => {
                        this.style.color = 'red';
                        this.message =error.response.data.error;
                        setTimeout(() => this.elementVisible = true, 0)

                    }
                );

            }
        }
    };
</script>

<style lang="scss" scoped></style>