import axios from "axios";

const instance = axios.create({
  baseURL: process.env.VUE_APP_BASE_URL,
  headers: {
    "Content-type": "application/json",
    'X-localization': (localStorage.getItem('X-localization')||'en'),
    'Authorization': 'Bearer ' + localStorage.getItem('jwt'),
    'Access-Control-Allow-Origin': '*'
  },
});

export default instance;
