<template>
   
     <main id="main">
      <section class="hero">
            
      </section>
      <Newsletter />
     </main><!-- End #main -->
 
</template>

<script>
  import Newsletter from '@/components/partials/Newsletter';

export default {
  name: 'ResetPassword',
  metaInfo:{
    title:'Reset Password'
  },
  components:{
    Newsletter,
  }
}
</script>

<style scoped>
.hero{
  background: rgba(0, 0, 255, 0.377);
  padding: 75px 0;
}
</style>