<template>
<section id="top_destinations">
    <div class="container">
        <div class="row">
            <div class="col">
                <h2 class="section_header">{{$t("sections.top_destinations.title_part1")}} <strong>{{$t("sections.top_destinations.title_part2")}}</strong></h2>
                <div class="after_title">
                    <div class="line"></div>
                    <div class="cercle yellow"></div>
                    <div class="cercle blue"></div>
                    <div class="cercle yellow"></div>
                    <div class="cercle blue"></div>
                    <div class="line"></div>
                </div>
                <swiper class="swiper" :options="swiperOption" data-swiper-autoplay="3000" :dir="isRtl">
                    <swiper-slide>
                        <div class="card">
                            <img src="../../../public/assets/img/destinations/destination1.webp" class="card-img-top" alt="">
                            <div class="card-body">
                                <h3>Flights to Istanbul</h3>
                                <h4>Turkey</h4>
                                <a class="call_action">{{$t("buttons.book")}}</a>
                            </div>
                        </div>
                    </swiper-slide>
                    
                    <swiper-slide>
                       <div class="card">
                            <img src="../../../public/assets/img/destinations/destination2.webp" class="card-img-top" alt="">
                            <div class="card-body">
                                <h3>Flights to Dubai</h3>
                                <h4>UAE</h4>
                                 <a class="call_action">{{$t("buttons.book")}}</a>
                            </div>
                        </div>
                    </swiper-slide>
                    <swiper-slide>
                        <div class="card">
                            <img src="../../../public/assets/img/destinations/destination3.webp" class="card-img-top" alt="">
                            <div class="card-body">
                                <h3>Flights to Sharm El Sheikh</h3>
                                <h4>Egypt</h4>
                                 <a class="call_action">{{$t("buttons.book")}}</a>
                            </div>
                        </div>
                    </swiper-slide>
                   <swiper-slide>
                        <div class="card">
                            <img src="../../../public/assets/img/destinations/destination4.webp" class="card-img-top" alt="">
                            <div class="card-body">
                                <h3>Flights to Riyadh</h3>
                                <h4>Saudi Arabia</h4>
                                 <a class="call_action">{{$t("buttons.book")}}</a>
                            </div>
                        </div>
                    </swiper-slide>
                    
                     <!-- If we need navigation buttons -->
                    <div class="swiper-button-prev" slot="button-prev"></div>
                    <div class="swiper-button-next" slot="button-next"></div>
                </swiper> 
            </div>
        </div>
    </div>
 
</section>

</template>

<script>
import { Swiper, SwiperSlide, directive } from 'vue-awesome-swiper';
import 'swiper/css/swiper.css';

export default {
    name:"TopDestinations",
    components: {
      Swiper,
      SwiperSlide,
    },
    directives: {
        swiper: directive
    },
    data() {
      return {
        isRtl: this.getLocale(),
        swiperOption: {
            slidesPerView: 4,
            spaceBetween: 30,
            direction: 'horizontal',
            loop: true,
            allowTouchMove: true,
            speed: 3000,          
            autoplay: {
            delay: 3000,
            centeredSlides: true,
            },
            navigation: {
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev'
          },
          breakpoints: {
            200:{
            slidesPerView:1,
            spaceBetween: 10,
            },
            640: {
            slidesPerView: 2,
            spaceBetween: 20,
            },
            768: {
            slidesPerView: 3,
            spaceBetween: 40,
            },
            1024: {
            slidesPerView: 4,
            spaceBetween: 50,
            },
        }
        }
      }
    },
    computed: {
      swiper() {
        return this.$refs.mySwiper.$swiper
      }
    },
    mounted() {
    },
    methods:{
        getLocale() {
            const locale = this.$route.params.locale =="ar" ? "rtl" :"ltr";
            return locale;
        }
    }
}
</script>

<style scoped>

#top_destinations{
    background-color: #f9f9f9;
    padding-bottom: 100px;
}

#top_destinations .card{
    margin: 25px 0 0;
    transition: 1s all ease-in-out;
}
#top_destinations .card-body{
    position: absolute;
    background: rgb(2,0,36);
    background: linear-gradient(5deg, rgba(2,0,36,1) 0%, rgba(19,22,80,0.7091211484593838) 60%, rgb(44 41 82) 100%);
    bottom: 0;
    height: 100%;
    display: flex;
    justify-content: flex-end;
    flex-direction:column ;
    width: 100%;
    align-items: flex-start;
     text-align: left;
     transition: 1s all ease-in-out;
}

#top_destinations .card-body:hover{
    cursor: pointer;
}

#top_destinations .card-body h3{
    color:#fff;
    font-size: 17px;
    font-weight: 400;
    min-height: 45px;
    text-align: initial;
}
#top_destinations .card-body h4{
    color:#fcf101;
    font-size: 15px;
    font-weight: 400;
}

#top_destinations .call_action{
    opacity: 0;
    display: none;
    transition: 0.5s all ease-in-out;
}

#top_destinations .card:hover .call_action{
    opacity: 1;
    display: block;
    transition:1s;
}

#top_destinations .card:hover .card-body{
    justify-content: center;
    text-align: center;
    transition: 1s;
}

#top_destinations .card:hover h3,
#top_destinations .card:hover h4{
    margin-left: auto;
    margin-right: auto;
    transition: 1s all ease-in-out;
}
.swiper-button-next:after, .swiper-container-rtl .swiper-button-prev:after,
.swiper-button-prev:after, .swiper-container-rtl .swiper-button-next:after{
    font-size: 16px;
    background: #ebebeb;
    padding: 10px 15px;
    border-radius: 50%;
    color: #131654;
    transition: 0.4s;
}
.swiper-button-next:hover:after, .swiper-container-rtl .swiper-button-prev:hover:after,
.swiper-button-prev:hover:after, .swiper-container-rtl .swiper-button-next:hover:after{
    color: #ebebeb;
    background: #131654;
}
.swiper-pagination-fraction, .swiper-pagination-custom, .swiper-container-horizontal > .swiper-pagination-bullets{
    top:0;
}

#our_partners .swiper-wrapper,
#our_partners .swiper-slide{
    display: flex;
    align-items: baseline;
    height: 140px;
}
.swiper-slide{
      display: -webkit-flex;
      display: flex;
      -webkit-justify-content: center;
      justify-content: center;
      align-items: center;
}

#our_partners .swiper-slide img{
    margin: auto;
}

.swiper-pagination-bullet{
    background: #131654;
    opacity: 1;
}
.swiper-pagination-bullet-active{
  background: #fcca01!important;
  opacity: 1;
}


</style>