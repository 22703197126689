<template>
   
     <main id="main">
      <section class="flights_section p-0">
        <div class="layer">
          <div class="container reservation" id="reservation-form" data-aos="fade-down">
            <div class="row">
                <div class="col-md-12 ">
                    <div class="reserve">
                      <CompactBooking /> 
                    </div>
                </div>
            </div>
          </div>
        </div>     
      </section>

      <section class="ecommerce">
        <div class="container">
          <div class="row">
            <!-- <SearchFiltersFlight />
            <SearchResultsFlight /> -->
            <div class="ok">
              <h1 class="text-uppercase">{{$t('header.menu.flights')}}</h1>
            </div>
            <h2 class="txt404">{{$t("notfount.rahalet")}}</h2>
          </div>
        </div>
      </section>
      <Newsletter />
     </main><!-- End #main -->
 
</template>

<script>
  import Newsletter from '@/components/partials/Newsletter';
  import CompactBooking from '@/components/partials/CompactBooking'
  // import SearchResultsFlight from '@/components/partials/SearchResultsFlight'
  // import SearchFiltersFlight from '@/components/partials/SearchFiltersFlight'

export default {
  name: 'Flights',
  metaInfo:{
    title:'Flights | Search Flight'
  },
  components:{
    Newsletter,
    CompactBooking,
    // SearchFiltersFlight,
    // SearchResultsFlight
  },
   
}

</script>

<style scoped>
.flights_section{
  height:550px;
  background-image: url(../../public/assets/img/banners/flights.webp);
}
.flights_section .layer{
  background: #131654cc;
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;
   
}
.ok{
  background-image: url(../../public/assets/404/flighr.webp);
  height: 259px;
  background-repeat: no-repeat;
  background-position: 50%;
  background-size: 21% auto;
}

</style>

