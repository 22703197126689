<template>
     <nav class="navbar navbar-expand-lg">
        <div class="container-fluid px-0">
            <LocalizedLink to="" class="logo navbar-brand scrollto px-2">
               <img src="../../../public/assets/logo.webp" alt="Rihlatna" class="img-fluid">
            </LocalizedLink>
            <div class="right_mobile-menu d-lg-none d-block d-flex mx-0">
              <a @click.prevent="changeLocale" id="lang_area">
                      <i class="fal fa-globe"></i>
              </a>
              <button class="navbar-toggler mx-3" id="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarScroll" aria-controls="navbarScroll" 
              aria-expanded="false" aria-label="Toggle navigation">
                  <i class="bi bi-list mobile-nav-toggle"></i>
              </button>
              
            </div>
            
            <div class="collapse navbar-collapse" id="navbarScroll">
            <ul class="navbar-nav me-auto my-2 my-lg-0 navbar-nav-scroll">
              <li class="nav-item">
                    <LocalizedLink to="/" class="nav-link scrollto" :class="{active: $route.name==='Home'}">{{$t("header.menu.home")}}</LocalizedLink>
                </li>
                <li class="nav-item">
                    <LocalizedLink to="/VisaFilling" class="nav-link scrollto" :class="{active: $route.name==='VisaFilling'}">{{$t("header.menu.visa")}}</LocalizedLink>
                </li>
                <li class="nav-item">
                    <LocalizedLink to="/flights" class="nav-link scrollto" :class="{active: $route.name==='Flights'}">{{$t("header.menu.flights")}}</LocalizedLink>
                </li>
                <li class="nav-item">
                <LocalizedLink to="/hotel" class="nav-link scrollto" :class="{active: $route.name==='Hotels'}">{{$t("header.menu.hotels")}}</LocalizedLink>
                </li>
                <li class="nav-item">
                <LocalizedLink to="/rent-car" class="nav-link scrollto" :class="{active: $route.name==='RentCar'}">{{$t("header.menu.rent_car")}}</LocalizedLink>
                </li>
                <li class="nav-item">
                <LocalizedLink to="/translation" class="nav-link scrollto" :class="{active: $route.name==='IDTranslation'}">{{$t("header.menu.translation")}}</LocalizedLink>
                </li>
                <li class="nav-item">
                <LocalizedLink to="/driving-licence" class="nav-link scrollto" :class="{active: $route.name==='DrivingLicence'}">{{$t("header.menu.driving_license")}}</LocalizedLink>
                </li>
                <li class="d-lg-none d-block"><LocalizedLink class="nav-link scrollto" to="/login" >{{$t('labels.signIn')}}/{{$t('labels.signUp')}}</LocalizedLink></li>
                <li class="d-lg-none d-block"><a href="#" class="nav-link scrollto">{{$t('labels.bookings')}}</a></li>
                
            </ul>
            <div class="right_menu">
              
                <div class="dropdown">
                  <LocalizedLink to="/login" data-bs-toggle="dropdown" aria-expanded="false" class="login_link" v-if="!currentUser" :class="{active: $route.name==='Login'}">{{$t('labels.signIn')}}</LocalizedLink>
              <LocalizedLink to="/dashboard" data-bs-toggle="dropdown" aria-expanded="false" class="login_link" v-if="currentUser" :class="{active: $route.name==='Login'}">{{currentUser.name||currentUser.userData.name}}</LocalizedLink>
                <!-- <a href="#" type="button" id="login_area" data-bs-toggle="dropdown" aria-expanded="false">   
                  <img src="../../../public/assets/img/icons/login.png" alt="Menu" width="25" class="unhover"/>
                  <img src="../../../public/assets/img/icons/login_active.png" alt="Menu" width="25"  class="hover"/>     
                </a> -->
                <ul class="dropdown-menu login_area" aria-labelledby="login_area">
                    <li><LocalizedLink to="/login" class="dropdown-item" v-if="!currentUser">{{$t('labels.signIn')}}/{{$t('labels.signUp')}}</LocalizedLink></li> 
                    <li><LocalizedLink to="/dashboard" class="dropdown-item"  v-if="currentUser">{{$t('labels.bookings')}}</LocalizedLink></li>
                    <li>
                      <a class="dropdown-item" @click.prevent="logOut" v-if="currentUser">
                        {{$t("dashboard.6")}}
                      </a>
                    </li>
                </ul>
                </div> 
                <div class="dropdown">
                <a @click.prevent="changeLocale" id="lang_area">
                    <i class="fal fa-globe"></i>
                </a>    
                </div>
                
            </div>
            </div>
        </div>
    </nav>
      
</template>

<script>
import LocalizedLink from '@/components/partials/LocalizedLink';
import {
    getSupportedLocales
  } from "@/util/i18n/supported-locales";
import { mapMutations } from 'vuex';
export default {
    name:"Nav",
    data(){
        return {
        locales: getSupportedLocales(),
    }
    },
    methods:{
      logOut() {
                this.reset_setMyName(this.currentUser);
                this.$store.dispatch('auth/logout');
                this.$router.push('Login');
            },
        changeLocale() {
            const path = this.$route.path.substring(this.$route.path.indexOf(this.$route.path) + 3, this.$route.path
            .length);
            const locale = localStorage.getItem('X-localization') === "en" ? "ar" : "en";
            if (path) {
            console.log('path',path)
            this.$router.push(`/${locale}/${path}`)
            } else {
            this.$router.push(`/${locale}`)
            }

            localStorage.setItem('X-localization', locale)
            window.location.reload();
        },
        ...mapMutations([
                'setMyName',
                'reset_setMyName'
            ])           
    },
     computed: {
            currentUser() {
                return this.$store.state.auth.user;
            }
        },
    components:{
        LocalizedLink
    },
    mounted() {
      if (this.currentUser) {
                this.setMyName(this.currentUser);
            }

    let navbar = document.querySelector(".navbar-collapse");
    if(navbar.length > 0){
    navbar.addEventListener('click', function() {
        navbar.classList.remove('show');
    });
    }
  },
  
}
</script>

<style scoped>
.navbar .dropdown:hover > ul {
  opacity: 1;
  top: 100%;
  visibility: visible;
  display: block;
  transition: 0.5s all ease-in-out;
  }
  html[lang="ar"] .navbar .dropdown > ul {
  left: 0;
  right: auto;
  }
html[lang="en"] .navbar .dropdown > ul {
  right: 0;
  left: auto;
  }
#navbarScroll ul{
   margin: auto;
}

#lang_area i{
  font-size: 29px;
  margin-right:10px;
  color: #fff;
}

.login_area li a{
  padding: 7px 10px;
  text-align: initial;
  background: transparent;
}

.login_area li:not(:last-child) a{
  border-bottom: 1px solid #ccc;
}

.login_area.dropdown-menu{
  padding: 0 10px;
  opacity: 0;
}

@media (min-width: 992px){
   .navbar-expand-lg .navbar-nav .nav-link {
    padding-right: 1.5rem;
    padding-left: 1.5rem;
    } 
}
.navbar-toggler{
  outline: #fff;
  box-shadow: none;
  border:1px solid #fff;
}

.dropdown-item{
  color: #131654 !important;
}

.dropdown-item:focus, .dropdown-item:hover {
    color: #fcca01 !important;
    background: transparent;
}

#login_area .hover{
  display: none;
  transition: 1s all ease-in-out;
}
#login_area:hover .hover{
  display: inline-flex;
}

#login_area:hover .unhover{
  display: none;
}

.right_menu a:hover i{
   color: #fcca01!important;
   transition: 1s all ease-in-out;
}

.right_menu{
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.right_menu a{
  padding-left: 10px;
}


.right_menu a img{
  max-width: 22px;
}

ul.dropdown-menu.mobile-logins{
  position: relative;
  display:block;
  background: transparent;
  border: none;
  box-shadow: none;
  opacity: 0;
  visibility: hidden;
  height: 0;
 transition: height 0.15s ease-out;
}

ul.dropdown-menu.mobile-logins.show{
  opacity: 1;
  visibility: visible;
  height:auto
}

@media screen and (max-width:767px){
  #navbarScroll ul{
    text-align: initial;
    padding: 0 20px;
    align-items: flex-start;
  }

  .navbar .dropdown ul a{
    color: #fff;
    padding: 7px 5px;
  }

  #header .logo img{
    width:75px;
  }

  #navbarDropdown.show{
    color: #fcca01;
  }


  
}
@media screen and (max-width:992px){
    .navbar #navbarScroll{
    background-color: #131654;
  }
}

.router-link-exact-active{
  color:#fcca01;
}
</style>