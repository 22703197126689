<template>
   <footer id="footer">
    <div class="footer-top">
      <div class="container">
        <div class="row">

          <div class="col-lg-3 col-md-6">
            <div class="footer-info">
               <a href="/" class="logo me-auto me-lg-0">
                  <img src="../../../public/assets/img/logo.webp" alt="Rahalatna" class="img-fluid" width="150">
              </a>
              <p class="footer_desc">
                {{$t("footer.footer_desc")}} 
              </p>
            </div>
          </div>

          <div class="col-lg-2 col-md-6 footer-links">
            <h4>{{$t("footer.useful_links.title")}}</h4>
            <ul>
              <li><i class="fal fa-chevron-right">&nbsp;</i> <a href="#">{{$t("footer.useful_links.children.link1")}}</a></li>
              <li><i class="fal fa-chevron-right">&nbsp;</i> <a href="#">{{$t("footer.useful_links.children.link2")}}</a></li>
              <li><i class="fal fa-chevron-right">&nbsp;</i> <a href="#">{{$t("footer.useful_links.children.link3")}}</a></li>
              <li><i class="fal fa-chevron-right">&nbsp;</i> <a href="#">{{$t("footer.useful_links.children.link4")}}</a></li>
              <li><i class="fal fa-chevron-right">&nbsp;</i> <a href="#">{{$t("footer.useful_links.children.link5")}}</a></li>
              <li><i class="fal fa-chevron-right">&nbsp;</i> <a href="#">{{$t("footer.useful_links.children.link6")}}</a></li>
              <li><i class="fal fa-chevron-right">&nbsp;</i> <a href="#">{{$t("footer.useful_links.children.link7")}}</a></li>
              <li><i class="fal fa-chevron-right">&nbsp;</i> <a href="#">{{$t("footer.useful_links.children.link8")}}</a></li>
            </ul>
          </div>

          <div class="col-lg-2 col-md-6 footer-links">
            <h4>{{$t("footer.top_airlines.title")}}</h4>
            <ul>
              <li><i class="fal fa-chevron-right">&nbsp;</i> <a href="#">{{$t("footer.top_airlines.children.link1")}}</a></li>
              <li><i class="fal fa-chevron-right">&nbsp;</i> <a href="#">{{$t("footer.top_airlines.children.link2")}}</a></li>
              <li><i class="fal fa-chevron-right">&nbsp;</i> <a href="#">{{$t("footer.top_airlines.children.link3")}}</a></li>
              <li><i class="fal fa-chevron-right">&nbsp;</i> <a href="#">{{$t("footer.top_airlines.children.link4")}}</a></li>
              <li><i class="fal fa-chevron-right">&nbsp;</i> <a href="#">{{$t("footer.top_airlines.children.link5")}}</a></li>
              <li><i class="fal fa-chevron-right">&nbsp;</i> <a href="#">{{$t("footer.top_airlines.children.link6")}}</a></li>
              <li><i class="fal fa-chevron-right">&nbsp;</i> <a href="#">{{$t("footer.top_airlines.children.link7")}}</a></li>
              <li><i class="fal fa-chevron-right">&nbsp;</i> <a href="#">{{$t("footer.top_airlines.children.link8")}}</a></li>
              <li><i class="fal fa-chevron-right">&nbsp;</i> <a href="#">{{$t("footer.top_airlines.children.link9")}}</a></li>
            </ul>
          </div>

          <div class="col-lg-2 col-md-6 footer-links">
            <h4>{{$t("footer.top_destinations.title")}}</h4>
            <ul>
              <li><i class="fal fa-chevron-right">&nbsp;</i> <a href="#">{{$t("footer.top_destinations.children.link1")}}</a></li>
              <li><i class="fal fa-chevron-right">&nbsp;</i> <a href="#">{{$t("footer.top_destinations.children.link2")}}</a></li>
              <li><i class="fal fa-chevron-right">&nbsp;</i> <a href="#">{{$t("footer.top_destinations.children.link3")}}</a></li>
              <li><i class="fal fa-chevron-right">&nbsp;</i> <a href="#">{{$t("footer.top_destinations.children.link4")}}</a></li>
              <li><i class="fal fa-chevron-right">&nbsp;</i> <a href="#">{{$t("footer.top_destinations.children.link5")}}</a></li>
              <li><i class="fal fa-chevron-right">&nbsp;</i> <a href="#">{{$t("footer.top_destinations.children.link6")}}</a></li>
              <li><i class="fal fa-chevron-right">&nbsp;</i> <a href="#">{{$t("footer.top_destinations.children.link7")}}</a></li>
              <li><i class="fal fa-chevron-right">&nbsp;</i> <a href="#">{{$t("footer.top_destinations.children.link8")}}</a></li>
              <li><i class="fal fa-chevron-right">&nbsp;</i> <a href="#">{{$t("footer.top_destinations.children.link9")}}</a></li>
              <li><i class="fal fa-chevron-right">&nbsp;</i> <a href="#">{{$t("footer.top_destinations.children.link10")}}</a></li>
            </ul>
          </div>

          <div class="col-lg-3 col-md-6 footer-newsletter">
            <h4>{{$t("footer.booking.title")}}</h4>
           
                <ul class="contact">
                  <li>
                    <a href="tel:+966-55 -124-44227" title="Email us">
                      <i class="fas fa-phone-alt">&nbsp;</i>+966-55 -124-44227
                    </a>
                  </li>
                  <li>
                    <i class="fas fa-map-marker-alt">&nbsp;</i>Eighth Avenue 487 Dammam, KSA
                  </li>
                  <li>
                    <a href="mailto:contact@rahalatna.com" title="Email us">
                      <i class="fas fa-envelope">&nbsp;</i>contact@rahalatna.com
                    </a>
                  </li>
                </ul>
                
           
               <h4 class="pt-5">{{$t("footer.booking.follow")}}</h4>
              <div class="social-links mt-3">
                <a href="#" class="facebook"><i class="bx bxl-facebook"></i></a>
                <a href="#" class="twitter"><i class="bx bxl-twitter"></i></a>               
                <a href="#" class="instagram"><i class="bx bxl-instagram"></i></a>
                <a href="#" class="linkedin"><i class="bx bxl-linkedin"></i></a>
                <a href="#" class="youtube"><i class="bx bxl-youtube"></i></a>

              </div>

              <div class="payments mt-3">
                <img src="../../../public/assets/img/payments.webp" alt="payments" class="img-fluid" />
              </div>
          </div>

        </div>
      </div>
    </div>

    <div class="container">
      <div class="copyright">
        &copy; 2022 {{ $t('footer.copyright.by') }} <a href="https://atsu-studio.com/" target="_blank">{{ $t('footer.copyright.atsu') }}</a>
      </div>
    </div>
    
  </footer><!-- End Footer -->

</template>

<script>
export default {
    name:'Footer',
    setup() {
        
    },
}
</script>